@import 'src/styles/variables';
@import 'src/styles/mixins';

.CarouselCustom {
  width: calc(100% - 1.5rem);
  margin: 0 0.75rem 48px;
  box-shadow: 0 14px 20px rgba($black-dark, 0.23),
    0 1.75302px 10.5612px rgba($black-dark, 0.115);
  position: relative;

  &__prev,
  &__next {
    @include transition;
    position: absolute;
    top: 50%;
    padding: 10px 15px;
    margin: -22px 0 0;
    border: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    background: none;
    color: $grey-light;
    outline: none;
    transform: scaleY(2);
    cursor: pointer;

    @include breakpoint(md) {
      display: none;
    }

    &:hover {
      color: darken($grey-light, 15%);
    }
  }

  &__next {
    right: 0;
  }

  .ant-carousel {
    width: 100%;

    .slick-dots {
      bottom: -37px;

      li {
        button {
          width: 8px;
          height: 8px;
          background: $silver;
          border-radius: 8px;
          opacity: 1;
        }

        &.slick-active {
          button {
            background: $blue-sea;
          }
        }
      }
    }
  }

  .LatestArticle {
    padding: 0;

    &__content {
      padding-bottom: 0;

      @include breakpoint(md-up) {
        height: 280px;
      }
    }

    &__info {
      padding-right: 0.75rem;
    }

    &__content {
      @include breakpoint(md) {
        display: block;
      }
    }

    &__banner {
      @include breakpoint(md) {
        margin-right: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 210px;
      }
    }

    &__info {
      @include breakpoint(md) {
        width: 100%;
        padding: 3px 12px 10px;
      }
    }

    &__title {
      margin-top: 10px;
    }
  }

  .PromotedArticle {
    background-color: var(--component-background);

    @include breakpoint(md) {
      height: 480px;
    }

    &__label {
      bottom: 6px;
      right: 6px;

      @include breakpoint(md) {
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .CommonBanner {
    height: 100%;
  }

  .PostInfo {
    &__date {
      display: inline;
    }
  }
}
