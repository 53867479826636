@import 'src/styles/variables';

.EditableContent {
  flex-direction: column;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 36px;
  transition: height 0.25s;

  &--active {
    height: 87px;

    .EditableContent__btns {
      padding-top: 15px;
      align-self: flex-end;
    }

    .EditableContent__input {
      width: 100%;
    }
  }

  &--error {
    height: 115px;
  }

  &__input {
    width: calc(100% - 38px);
  }

  &__row {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    transition: height 0.25s;
    width: 100%;
  }

  .ant-input[disabled] {
    font-size: 18px;
    color: var(--title);
    background: none;
    border: 1px solid var(--button-border-color);
    cursor: default;
  }

  .ant-input-sm {
    font-size: 18px;
    height: 36px;
  }
}
