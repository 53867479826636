@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

// Todo: clarify
@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  flex-grow: 1;
  flex-basis: $flex-basis;
  min-width: $flex-basis;
  max-width: 100%;

  $multiplier: 1;
  $current-width: 0px;

  @while $current-width < $max-viewport-width {
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media (min-width: $flex-basis * $multiplier) {
      max-width: percentage(1 / $multiplier);
    }
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  min-height: $lineHeight * $lineCount;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      $bgColor 100%
    );
  }
}
// Todo: keep
// breakpoints mixin
// @param {String} $point - name of breakpoint
// add '-up' if you want (min-width: $point) and leave with no suffix for (max-width: $point - 1px)
@mixin breakpoint($point) {
  $query-direction: '';
  $query-point: '';
  $query-size: '';

  @if str-index($point, '-up') {
    $query-direction: 'min-width';
    $query-point: str-slice($point, 0, str-length($point) - str-length('-up'));
    $query-size: map-get($breakpoints, $query-point);
  } @else {
    @if str-index($point, '-down') {
      $query-point: str-slice(
        $point,
        0,
        str-length($point) - str-length('-down')
      );
    } @else {
      $query-point: $point;
    }

    $query-direction: 'max-width';
    $query-size: map-get($breakpoints, $query-point) - 1px;
  }

  @media screen and (#{$query-direction}: #{$query-size}) {
    @content;
  }
}

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin draftState {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: #efefef;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}

// Todo: keep
@mixin transition {
  transition: 0.3s;
}

// Todo: keep
@mixin icomoon($iconCode) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: $iconCode;
  }
}
