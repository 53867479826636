@import 'src/styles/variables';
@import 'src/styles/mixins';

.ImageInput2 {
  margin-bottom: 26px;

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__info {
    color: $white;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0.5rem;
    background-color: var(--imageInput-info-background);
    border-radius: 4px;
    box-shadow: 0 0 2px 2px var(--imageInput-info-background);
  }

  .ant-upload-text {
    display: block;
    padding: 12px 0 0;
    font-size: 16px;
    line-height: 1.3;
  }

  .ant-upload.ant-upload-drag {
    background: var(--imageInput-background);
    min-height: 474px;
    display: flex;
    align-items: center;
    padding: 50px;
    border: 0;

    @include breakpoint(md) {
      min-height: 300px;
    }
  }

  .ProgressBar2 {
    z-index: 3;
    background: #dadada;
  }
}
