@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.EventDates {
  max-width: 76px;
  min-width: 76px;
  padding-right: 7px;
  font-size: 22px;
  line-height: 1.3;
  word-break: break-all;

  &__date {
    color: var(--text);
  }

  @include breakpoint(sm) {
    font-size: 18px;
    min-width: 50px;
    max-width: 50px;
  }

  strong {
    display: block;
    font-size: 30px;
    font-weight: 600;
    color: var(--title);

    @include breakpoint(sm) {
      font-size: 26px;
    }
  }
}
