@import "src/styles/variables";
@import "src/styles/mixins";

.AppsInfo {
  background: #f7f7f7;


  &__holder {
    display: flex;
    max-width: 700px;
    margin: 0 auto;
    padding: 140px 30px 160px;

    @include breakpoint(md) {
      padding-top: 40px;
      padding-bottom: 74px;
      display: block;
    }

    >img {
      align-self: baseline;
      max-width: 282px;
      display: block;

      @include breakpoint(md) {
        margin: 0 auto;
      }
    }
  }

  &__text {
    padding: 140px 40px 20px;

    @include breakpoint(md) {
      padding: 30px 0 20px;
      max-width: 260px;
      margin: 0 auto;
    }

    h4 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 1rem;

      strong {
        display: block;
        padding-top: 10px;
        font-size: 23px;
        line-height: 28px;
      }
    }
  }

  .Footer__brands {
    padding-top: 15px;
    margin-top: 0 !important;

    a {
      margin-left: 20px;
    }

    img {
      width: 120px;
    }
  }
}
