@import 'src/styles/mixins.scss';
@import 'src/styles/variables.scss';


.ProviderCountryFilter {
  min-width: 200px;
  max-width: 310px;

  @include breakpoint(sm) {
    padding: 1rem 0;
    max-width: 100%;
  }

  .ant-select-selection {
    max-height: 45px;
    overflow: hidden;
  }

  .ant-select {
    width: 100%;
  }
}
