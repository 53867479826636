// margin left
.app-ml-0 {
  margin-left: 0 !important;
}

.app-ml-1 {
  margin-left: 0.5rem !important;
}

.app-ml-2 {
  margin-left: 1rem !important;
}

.app-ml-3 {
  margin-left: 1.5rem !important;
}

.app-ml-4 {
  margin-left: 2rem !important;
}

.app-ml-5 {
  margin-left: 2.5rem !important;
}

// margin right
.app-mr-0 {
  margin-right: 0 !important;
}

.app-mr-1 {
  margin-right: 0.5rem !important;
}

.app-mr-2 {
  margin-right: 1rem !important;
}

.app-mr-3 {
  margin-right: 1.5rem !important;
}

.app-mr-4 {
  margin-right: 2rem !important;
}

.app-mr-5 {
  margin-right: 2.5rem !important;
}

// margin bottom
.app-mb-0 {
  margin-bottom: 0 !important;
}

.app-mb-1 {
  margin-bottom: 0.5rem !important;
}

.app-mb-2 {
  margin-bottom: 1rem !important;
}

.app-mb-3 {
  margin-bottom: 1.5rem !important;
}

.app-mb-4 {
  margin-bottom: 2rem !important;
}

.app-mb-5 {
  margin-bottom: 2.5rem !important;
}

// padding right
.app-pr-0 {
  padding-right: 0 !important;
}

.app-pr-1 {
  padding-right: 0.5rem !important;
}

.app-pr-2 {
  padding-right: 1rem !important;
}

.app-pr-3 {
  padding-right: 1.5rem !important;
}

.app-pr-4 {
  padding-right: 2rem !important;
}

.app-pr-5 {
  padding-right: 2.5rem !important;
}

// padding left
.app-pl-0 {
  padding-left: 0 !important;
}

.app-pl-1 {
  padding-left: 0.5rem !important;
}

.app-pl-2 {
  padding-left: 1rem !important;
}

.app-pl-3 {
  padding-left: 1.5rem !important;
}

.app-pl-4 {
  padding-left: 2rem !important;
}

.app-pl-5 {
  padding-left: 2.5rem !important;
}

// paddint top
.app-pt-0 {
  padding-top: 0 !important;
}

.app-pt-1 {
  padding-top: 0.5rem !important;
}

.app-pt-2 {
  padding-top: 1rem !important;
}

.app-pt-3 {
  padding-top: 1.5rem !important;
}

.app-pt-4 {
  padding-top: 2rem !important;
}

.app-pt-5 {
  padding-top: 2.5rem !important;
}

// padding bottom
.app-pb-0 {
  padding-bottom: 0 !important;
}

.app-pb-1 {
  padding-bottom: 0.5rem !important;
}

.app-pb-2 {
  padding-bottom: 1rem !important;
}

.app-pb-3 {
  padding-bottom: 1.5rem !important;
}

.app-pb-4 {
  padding-bottom: 2rem !important;
}

.app-pb-5 {
  padding-bottom: 2.5rem !important;
}
