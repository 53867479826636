@import 'src/styles/variables';
@import 'src/styles/mixins';

.ant-btn.ContactUsButton {
  color: white;
  background-color: $color-green-light;
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  min-width: 150px;

  @include breakpoint(sm) {
    min-width: auto;
    font-size: 14px;
    top: 20px;
    padding: 0 10px;
  }
}
