@import 'src/styles/variables';

.AddressCard {
  padding: 0 60px 0 0;
  font-size: 15px;
  min-width: 200px;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__title {
    display: block;
    font: bold 16px/1.7 $font-ttl;
    color: var(--title);
  }
}
