.Analytics {
  overflow-x: auto;

  &--bordered {
    border-left: 1px solid lightgray;
  }

  &__frame {
    height: calc(100vh - 72px);
    width: calc(100% - 1px);
    border: none;
  }

  &__message {
    margin-top: 1em;
  }
}
