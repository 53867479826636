@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('/assets/font/Titillium-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('/assets/font/Titillium-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('/assets/font/Titillium-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
