@import 'src/styles/variables';
@import 'src/styles/mixins';

.ExtendedFields {
  &__collapseHeader {
    font: bold 26px/1.3 $font-ttl;
    text-transform: uppercase;
  }

  &__tabHeader {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__table {
    margin: 0 0 0 15px;
    width: auto;
    table-layout: fixed;
    font-size: 14px;
    line-height: 1.8;
    text-align: left;

    th,
    td {
      padding: 0 10px 0 0;
      color: var(--text);
      white-space: pre-line;
      vertical-align: top;
    }

    th {
      font-weight: normal;
      color: $grey-medium-2;
      width: 135px;
    }

    .ExtendedFields__paragraph {
      &:first-child {
        th {
          padding-top: 20px;
          padding-bottom: 6px;
        }
      }

      th {
        padding-top: 40px;
        font: bold 16px/1.6 $font-inter;
        color: var(--title);
      }
    }

    .ExtendedFields__listItem {
      th {
        padding-top: 10px;
        font: 16px/1.6 $font-inter;
        color: var(--title);
      }
    }
  }
}
