@import 'src/styles/variables';

.ServicePlan {
  display: flex;
  flex-direction: column;

  &__figure {
    img {
      max-width: 100%;
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-ttl;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    padding: 2rem 0 1.5rem;
    margin: 0;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      padding-top: 1rem;
    }
  }

  &__text {
    text-align: center;
    font-family: $font-ttl;
    font-weight: 600;
    line-height: 1.45;
    color: grey;
    margin-top: 1rem;
    padding: 0 1rem;
  }

  &__main {
    background-color: white;
    padding: 10px;
  }

  &__form {
    max-width: 600px;
    margin: 0 auto 32px;
  }

  &__button {
    background-color: $color-green !important;
    font-family: $font-ttl;
    font-weight: 400;
    font-size: 29px;
    color: white;
    width: 150px;

    &--holder {
      background-color: white;
      text-align: center;
      padding: 0 0 3rem;
    }
  }

  .ant-form-item-no-colon {
    color: $black-dark;
  }

  div.ant-select-selector,
  .ant-input {
    background-color: $white !important;
    color: $text-color;
  }
}
