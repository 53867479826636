@import 'src/styles/variables';
@import 'src/styles/mixins';

.Company {
  padding: 0 0 100px;
  user-select: none;

  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 49px;
    margin: 0 0 16px;
  }

  h2,
  h3 {
    font-size: 19px;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0 0 12px;
  }

  h3 {
    margin: 0 0 16px;
  }

  .company-data {
    margin-bottom: 48px;
  }

  .app-info-table {
    max-width: 850px;
  }

  .GoBackButton {
    margin-top: 16px;
  }
}
