@import 'src/styles/variables';

.ProviderCategoryFilter {
  &__wrapper {
    margin: 16px 0 24px;
  }

  &__title {
    margin: 1.5rem 0 0;
  }

  &__select {
    width: 100%;
  }

  &__label {
    text-transform: uppercase;
    font-family: $font-ttl;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 8px;

    &--sub {
      display: block;
      color: var(--title);
      font-family: $font-inter;
      line-height: 15px;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }
}
