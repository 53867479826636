@import 'src/styles/variables';

.VesselFilter {
  &__button {
    position: absolute;
    top: 51px;
    right: 10px;
    z-index: 1000;
    height: 38px;
    width: 38px;
    border-radius: 20px !important;
    padding: 0;
    border: 0 !important;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;

    &-content {
      position: relative;
    }
  }

  &__indicator {
    position: absolute;
    background-color: $blue-sea;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: -3px;
    right: 7px;
  }

  .VesselFilter__label {
    font-family: $font-inter;
    line-height: 15px;
    font-size: 12px;
    text-transform: uppercase;
  }
}
