@import 'src/styles/variables';
@import 'src/styles/mixins';

.Expander {
  padding: 20px 0;
  border-bottom: 1px solid var(--button-border-color);

  &__slider {
    height: 100%;
    width: 100%;
  }

  &__content {
    padding-top: 20px;
    padding-bottom: 32px;
    padding-left: 2px;
  }

  &__title {
    font: bold 26px/1.3 $font-ttl;
    text-transform: uppercase;
    margin: 0;
  }

  .ant-collapse {
    background: none;

    > .ant-collapse-item {
      border: 0;
    }
  }

  .ant-collapse-content {
    border: 0;
    padding-top: 20px;

    > .ant-collapse-content-box {
      padding: 20px 0 25px;
    }
  }
}
