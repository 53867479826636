.VesselComparison {
  display: flex;
  margin-right: -20px;

  .VesselPage {
    padding-right: 20px;
    width: 50%;

    &__top {
      flex-direction: column-reverse;
    }

    &__gallery {
      height: 100%;
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }

    &__info {
      width: 100%;
    }

    .VesselOwner__pane {
      padding-bottom: 15px;
    }

    .image-gallery-fullscreen-button {
      bottom: 0 !important;
    }
  }

  .GoBackButton {
    display: none;
  }
  .VesselPhotos {
    padding: 0;
  }
}
