@import 'src/styles/variables';

.VesselCompare {
  padding-top: 1rem;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    margin-right: 6px;
    background: #4e74fc;
    border-radius: 150px;
    line-height: 28px;
    padding: 0 1rem;
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }

  &__button {
    line-height: 26px;
    height: 28px;
    border-radius: 150px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__tag.ant-tag {
    background: none;
    border-radius: 150px;
    color: $grey-medium-2;
    display: inline-block;
    padding: 0 0.75rem;
    position: relative;
    margin: 0 6px 0 0;
    line-height: 26px;
    height: 28px;
    font-size: 16px;
    border: 1px solid #e7e7e6;
  }
}
