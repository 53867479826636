@import 'src/styles/variables';

.ProductCard {
  min-width: 286px;
  max-width: 286px;
  display: block;
  margin-right: 18px;

  &__photo {
    overflow: hidden;
    display: flex;
    height: 160px;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px !important;
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

    img {
      width: 100%;
    }
  }

  &__name {
    font: bold 18px/24px $font-ttl;
    margin: 0;
    text-transform: capitalize;
    color: var(--title) !important;
  }
}
