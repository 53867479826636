@import 'src/styles/variables';
@import 'src/styles/mixins';

.NewsCard {
  $self: &;

  position: relative;
  width: 33.33333333%;
  padding: 0 0.75rem 1rem;
  margin-bottom: 4rem;

  @include breakpoint(lg) {
    width: 50%;
  }

  @include breakpoint(sm) {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__banner {
    &:before {
      padding-top: 52% !important;
    }

    &--figure {
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  &__holder {
    padding-top: 0.75rem;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 0 0.65rem;
    text-transform: uppercase;

    &--draft {
      color: $grey-medium;
    }
  }

  &__text {
    display: block;
  }

  &__description {
    @include multiLineEllipsis(
      $lineHeight: 1.5rem,
      $lineCount: 4,
      $bgColor: var(--body-background)
    );
    color: var(--text);
    font-size: 15px;

    &--draft {
      color: $grey-medium;
    }
  }

  &__issuer {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0;
  }

  &__date {
    font-size: 0.75rem;
  }

  &__promo {
    text-align: right;
    margin: 0.5rem 0 0.5rem;
  }

  &--horizontal {
    padding: 0 0 1.5rem;
    margin-bottom: 0;
    width: 100%;

    @include breakpoint(sm-up) {
      display: flex;
    }

    #{$self}__header {
      display: flex;
      flex-direction: column-reverse;

      @include breakpoint(sm-up) {
        margin-top: -4px;
      }
    }

    #{$self}__description {
      @include breakpoint(sm-up) {
        @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 2);
      }

      @include breakpoint(xlg-up) {
        @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 3);
      }
    }

    #{$self}__holder {
      padding-top: 20px;
      width: 100%;

      @include breakpoint(sm-up) {
        padding-top: 0;
      }
    }

    #{$self}__banner {
      min-width: 100%;
      max-width: 100%;

      @include breakpoint(sm-up) {
        max-width: 34%;
        min-width: 34%;
        margin-right: 20px;
        align-self: flex-start;
      }
    }
  }
}
