@import 'src/styles/variables';
@import 'src/styles/mixins';

.Services {
  margin-top: 1rem;

  &__register {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    line-height: 1;

    &--add {
      color: white;
      font-size: 28px;
      margin-right: 0.25rem;
    }
  }

  &__button.ant-btn-primary {
    border-color: $color-green-light;
    background-color: $color-green-light;

    &:focus,
    &:hover {
      background: #2e344e;
      color: $border-hover;
    }
  }

  &__title-holder {
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__header {
    flex-wrap: wrap;
    padding-top: 0;
  }

  &__GoBackButton {
    margin-top: 8px;
  }

  &__list .ServiceCard__content {
    margin-left: 0;

    @include breakpoint(xlg-up) {
      margin-left: 11px;
    }
    @include breakpoint(xl-up) {
      margin-left: 0;
    }
  }
}
