@import 'src/styles/variables';
@import 'src/styles/mixins';

.MapModeSwitch {
  position: absolute;
  z-index: 1111;
  top: 30px;
  left: 50%;
  background: var(--body-background);
  transform: translateX(-50%);
  border-radius: 20px;
  padding: 0 13px;
  border: 1px solid var(--button-border-color);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  .ant-radio-group {
    vertical-align: top;
    white-space: nowrap;
  }

  label.ant-radio-button-wrapper {
    @include transition;
    color: var(--button-map);
    border: none;
    background: none;
    outline: none !important;
    border-radius: 0;
    vertical-align: top;
    box-shadow: none !important;
    font-weight: 600;
    height: auto;
    line-height: 36px;
    padding: 0 12px;

    @include breakpoint(sm) {
      padding: 0 10px;
    }

    &:hover,
    &.ant-radio-button-wrapper-checked {
      color: $blue-sea;
    }

    &:before {
      display: none;
    }
  }

  .icon-custom {
    font-size: 22px;
    position: relative;
    top: -1px;
  }
}
