@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.SchedulePane {
  margin-bottom: 44px;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    margin: 0;
  }

  &__group {
    display: flex;

    @include breakpoint(md) {
      flex-direction: column;
    }
  }

  &__date {
    min-width: 76px;
    padding-top: 3px;
    color: $grey-medium;

    @include breakpoint(md) {
      min-width: 100%;
      margin-bottom: 10px;
    }

    strong {
      color: var(--title);
    }
  }

  &__items {
    margin: 0;
    padding: 0;
  }
}
