@import 'src/styles/variables';

.leaflet-popup {
  width: 214px !important;
}

.PointMark {
  color: var(--title);
  font-size: 10px;
  line-height: 1.6;
  position: relative;

  &__info {
    position: relative;
    z-index: 100;
    width: 214px;
    padding: 8px 8px 4px;
    background-color: var(--body-background);
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    overflow: hidden;

    p {
      margin: 0 !important;
      color: var(--title) !important;

      span {
        display: inline-block;
        color: var(--text);
        min-width: 60px;
        padding-right: 5px;
      }
    }
  }

  &__name {
    font-weight: bold;
    color: var(--title);
    font-size: 14px;
    line-height: 1.8;
    margin: 0 0 8px -2px;
    padding: 0 0 0 2px;
    cursor: ne-resize;
    max-width: 90%;
  }

  &__flag {
    display: inline-block;
    margin-right: 8px;

    img {
      max-height: 16px;
      vertical-align: -3px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
    }
  }

  &__vessel {
    cursor: pointer;
    width: 100%;
  }

  &__photo {
    display: block;
    margin: 0 -8px 4px !important;
    max-width: calc(100% + 16px);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        $grey-medium-2 0%,
        rgba(151, 150, 147, 0) 100%
      );
    }
  }

  &__close {
    z-index: 99999;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 18px/16px Tahoma, Verdana, sans-serif;
    color: orange;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
  }

  &__fav {
    position: absolute;
    top: 42px;
    right: 1px;
    z-index: 2;
    font-size: 22px;
    color: white;
    border-radius: 50%;

    &.icon-star-filled {
      color: $filled-star-color;
    }
  }
}
