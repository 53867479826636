@import 'src/styles/variables';

.EmptyList {
  &__invitation {
    padding: 20px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    b {
      padding-top: 18px;
      display: block;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.3;
      color: $grey-medium-2;
    }
  }
}
