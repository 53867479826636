@import 'src/styles/variables';
@import 'src/styles/mixins';

.VideoCard {
  min-width: 442px;
  max-width: 442px;
  margin-right: 18px;

  @include breakpoint(md) {
    min-width: 286px;
    max-width: 286px;
  }

  &__video {
    width: 100%;
    height: 240px;
    overflow: hidden;
    border: none;
    margin-bottom: 9px;
    object-fit: cover;

    @include breakpoint(md) {
      height: 160px;
    }
  }

  &__name {
    font: bold 18px/24px $font-ttl;
    margin: 0;
    text-transform: capitalize;
    color: var(--title) !important;
  }

  .ProductCard__photo {
    height: 240px;

    @include breakpoint(md) {
      height: 160px;
    }
  }
}
