@import 'src/styles/variables';

.LoginForm {
  width: 100%;
  max-width: 350px;

  &__title {
    font: bold 28px/1.3 $font-ttl;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 36px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;

    div {
      width: 100%;
    }

    .ant-btn {
      line-height: 1;
      height: auto;
      color: #979693;
      border-bottom: 1px solid transparent !important;

      &:hover {
        color: $blue-sea;
        border-color: $blue-sea !important;
      }
    }
  }

  &__submit {
    width: 100%;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px var(--component-background) inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--title);
  }

  .ant-input-password,
  .ant-input {
    background: var(--component-background);
  }
  .ant-form-item-explain {
    color: $error;
  }
}
