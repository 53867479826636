@import 'src/styles/variables';
@import 'src/styles/mixins';

.PersonCard {
  max-width: 664px;
  padding: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  margin: 38px 0;
  overflow: hidden;
  background-color: var(--component-background);

  @include breakpoint(md) {
    display: block;
    padding: 20px;
  }

  &__img-holder {
    border: solid 1px rgba(0, 0, 0, 0.05);
    width: 150px;
    height: 150px;
    border-radius: 100%;
    padding: 4px;

    @include breakpoint(md) {
      margin: 0 auto 20px !important;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    img {
      height: 100%;
    }
  }

  &__info {
    flex: auto;
    padding: 0 0 0 32px;

    @include breakpoint(md) {
      padding: 0;
    }
  }

  &__name {
    display: block;
    font: bold 28px/24px $font-ttl;
    margin-bottom: 16px;
    color: var(--title);

    @include breakpoint(md) {
      font: bold 22px $font-ttl;
    }
  }

  &__list {
    margin: 0;
    color: $black;

    &--contacts {
      p {
        margin: 0 0 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: none !important;
        color: $black;
      }
    }

    dt {
      width: 100px;
      padding: 0 5px 4px 0;
      float: left;
      clear: both;
      color: $grey-medium-2;
    }

    dd {
      margin: 0;
      padding-bottom: 4px;
      overflow: hidden;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
