@import 'src/styles/variables';

.PersonContacts {
  width: 100%;
  margin-top: 16px;

  th {
    font-weight: 200;
    color: $grey-medium;
    padding: 3px 10px 3px 0;
    width: 50%;
  }

  td {
    padding: 3px 10px 3px 0;
  }

  &__link {
    color: var(--title);
    &:hover {
      color: $grey-medium;
    }
  }
}
