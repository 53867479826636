@import 'src/styles/variables';
@import 'src/styles/mixins';

.LatestArticle {
  width: 100%;
  padding: 0 0.75rem;

  &:before {
    padding-top: 25.64% !important;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    position: relative;
  }

  &__banner {
    margin-right: 12px;

    @include breakpoint(sm) {
      right: -1.25rem !important;
      left: -1.25rem !important;
    }
  }

  &__info {
    width: 50%;
    overflow: hidden;
    position: relative;
    padding-left: 0.75rem;

    @include breakpoint(lg) {
      width: 40%;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 3px 0 0.5rem;
    text-transform: uppercase;

    &--draft {
      color: $grey-medium;
    }
  }

  &__description {
    @include multiLineEllipsis(
      $lineHeight: 1.5rem,
      $lineCount: 4,
      $bgColor: var(--body-background)
    );
    color: var(--text);
    font-size: 15px;

    &--draft {
      color: $grey-medium;
    }

    @media (max-width: 1024px) {
      @include multiLineEllipsis(
        $lineHeight: 1.5rem,
        $lineCount: 3,
        $bgColor: var(--body-background)
      );
    }
  }
}
