@import 'src/styles/variables';
@import 'src/styles/mixins';

.Footer {
  background: $primary;
  padding: 36px 36px 41px;
  position: relative;
  min-height: 183px;
  color: $white;

  @include breakpoint(lg) {
    padding-bottom: 30px;
  }

  @include breakpoint(md) {
    padding: 20px 16px 40px;
  }

  a {
    color: $white;
  }

  &__main,
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main {
    @include breakpoint(md) {
      display: block;
    }
  }

  &__nav {
    @include breakpoint(lg) {
      display: block;
    }
  }

  &__links {
    @include list-reset;
    display: flex;
    padding: 0 0 0 5px;

    @include breakpoint(lg) {
      padding-top: 17px;
      padding-left: 0;
      display: block;
    }

    li {
      padding: 5px 0 5px 55px;

      @include breakpoint(lg) {
        padding-left: 0;
      }
    }
  }

  &__copyright {
    font-size: 12px;

    @include breakpoint(lg) {
      align-self: baseline;
      padding-top: 10px;
    }

    @include breakpoint(md) {
      color: #C4C4C4;
      font-size: 10px;
    }
  }

  &__brands {
    display: flex;
    padding-top: 26px;

    @include breakpoint(lg) {
      margin-top: -44px;
      padding-top: 0;
    }

    a {
      margin-left: 32px;
      display: block;

      &:first-child {
        margin-left: 0;
      }
    }

    img {
      width: 150px;
      display: block;
    }
  }
}
