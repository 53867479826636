@import 'src/styles/mixins.scss';
@import 'src/styles/variables.scss';

.TimeLine {
  align-items: stretch;
  position: fixed;
  left: 53px;
  bottom: 13px;
  word-wrap: break-word;
  transition: width 1s;


  &__day {
    box-sizing: border-box;
    text-align: left;
    padding: 6px 0 0 8px;
    font-size: 12px;
    font-family: monospace;
    line-height: 1;
    height: 23px;
    overflow: hidden;
    width: 10%;
    text-shadow: 0 0 4px black;
    color: #fff3e1;
    white-space:normal;

    @include breakpoint(sm) {
      font-size: 9px;
      font-weight: 700;
      padding: 3px 0 0 2px;
    }

    &:not(:first-child) {
      border-left: 1px solid rgba(68, 65, 65, 0.84);
    }
  }

}
