@import 'src/styles/variables';
@import 'src/styles/mixins';

.ServiceProvider {
  padding-bottom: 20px;

  &__banner {
    position: relative;

    @include breakpoint(xlg) {
      margin: 0 -20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    min-width: 120px;
  }

  &__actionButton {
    text-align: left !important;
  }

  &__section {
    display: flex;
    justify-content: space-between;

    @include breakpoint(sm) {
      display: block;
    }
  }

  &__group {
    .VesselCardWrapper {
      margin-right: 20px;

      &__title {
        display: none;
      }

      .VesselCard__inside {
        margin: 0;
      }
    }
  }

  .ServiceLogo {
    bottom: 0;
    left: 20px !important;
    transform: translateY(50%);
  }
}
