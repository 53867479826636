@import 'src/styles/variables';
@import 'src/styles/mixins';

.CategoryList {
  @include breakpoint(md) {
    padding-top: 20px;
  }

  &__wrapper {
    display: block;
    width: 100%;
  }

  &__header {
    color: lightgrey;
  }

  &__item {
    width: 100%;
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 52%;
    }

    &--figure {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  &__name {
    text-transform: uppercase;
    line-height: 24px;
    font-size: 16px;
    margin: 6px 0;
    font-weight: bold;
  }

  img {
    width: 150%;
  }
}
