@import 'src/styles/variables';
@import 'src/styles/mixins';

.RecentArticles {
  padding-bottom: 50px;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 22px;
  }

  &__loader {
    width: 100%;
    padding: 0 2rem 1rem;
  }
}
