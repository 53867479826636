.TestPage {
  //height: 100%;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //padding: 3rem;

  &__container {
    width: 100%;
    margin-bottom: 2rem;
    //border: dotted 1px grey;
  }
  
}
