@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.UserDetailsOwner {
  &__person {
    background: #F4F4F4;
    margin: 0 0 44px 70px;
    padding: 7px 0;
    border-radius: 2px;

    @include breakpoint(md) {
      margin-left: -76px;
    }

    table {
      width: 100%;
      table-layout: fixed;

      th,
      td {
        padding: 3px 10px;
        font-weight: normal;
        vertical-align: top;
      }

      th {
        color: $grey-medium-2;
        width: 100px;
      }

      td {
        color: $black;
        word-break: break-all;
      }
    }
  }
}