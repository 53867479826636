// Todo: move to map component
@import './variables.scss';
@import './mixins.scss';

#eezMap,
#seabedMap,
#leafletMap,
#windy {
  #logo {
    top: -555px;
  }

  #calendar,
  #mobile-calendar {
    display: none;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .leaflet-popup-content-wrapper {
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .leaflet-popup-content {
    margin: 0 !important;
  }

  .leaflet-popup-tip {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;
    background-color: $white !important;
    z-index: 100;
  }

  .leaflet-popup-close-button {
    z-index: 999 !important;
    top: 0;
    font-size: 20px;
    right: -3px;
    width: 40px;
    height: 42px;
    line-height: 1.5;
    color: $text-color;
  }

  .leaflet-popup-tip-container {
    height: 13px !important;
  }

  .leaflet-control {
    border: 0;
    border-radius: 3px;
    background: var(--body-background);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

  .leaflet-control-container {
    display: block !important;
  }

  .leaflet-control-coordinates {
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.7);
    padding: 0 12px;
    margin: 0 50px 0 0 !important;
    color: $black;
    font-weight: 600;
    border-radius: 2px;

    @include breakpoint(md) {
      margin-right: 0 !important;
      width: 100vw;
      justify-content: center;
    }

    &-message {
      font-size: 8px;
      color: grey;
    }

    &-block {
      font-size: 14px;
      min-width: 120px;

      @include breakpoint(md) {
        min-width: auto;
      }

      b {
        font-weight: 600;
      }

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  #mobile-ovr-select {
    display: none !important;
  }

  #embed-zoom {
    left: 15px !important;
    top: 80px;
    right: auto;
    transform: scale(1) !important;
    background: var(--body-background);
    border: 1px solid $text-color;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 36px;
    z-index: 1000;

    @include breakpoint(md) {
      top: 75px !important;
    }

    .zoom-ctrl {
      @include transition;
      height: 43px !important;
      line-height: 43px !important;
      width: 100% !important;
      background: none !important;
      color: var(--title);
      font-size: 16px !important;
      border-radius: 0 !important;
      margin-bottom: 0 !important;

      &:hover {
        color: $blue-sea !important;
      }

      &:first-child {
        border-bottom: 1px solid rgba(196, 196, 196, 0.3);
      }
    }
  }

  .leaflet-control-zoom {
    border: 1px solid var(--button-border-color) !important;

    &.leaflet-bar {
      border-radius: 20px !important;

      a {
        height: 43px !important;
        line-height: 43px !important;
        color: var(--title);

        &:first-child {
          border-bottom: 1px solid rgba(196, 196, 196, 0.3) !important;
        }
      }
    }
  }

  .leaflet-top .leaflet-control {
    margin-top: 35px;

    @include breakpoint(md) {
      margin-top: 75px;
    }
  }

  .leaflet-left .leaflet-control {
    margin-left: 15px !important;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 20px !important;
  }

  .leaflet-bottom {
    position: fixed !important;

    .leaflet-control-attribution {
      display: none !important;
    }
  }

  .leaflet-control-zoom-fullscreen {
    @include icomoon('\e907');
    background: var(--body-background) !important;
    font-size: 22px;
    border: 0 !important;
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    border-radius: 10px !important;

    &:before {
      color: var(--text-active);
    }

    &[title~='Exit'] {
      @include icomoon('\e908');
    }
  }

  // player styles are hidden
  #logo-wrapper {
    display: none;
  }

  #bottom {
    z-index: 500;
    position: fixed !important;
  }
}

.leaflet-touch {
  .leaflet-bar {
    border: 0 !important;

    a {
      @include transition;
      background-color: transparent;
      border-radius: 0 !important;
      border: 0 !important;
      width: 36px;
      height: 36px;
      line-height: 36px;

      &:hover {
        color: $blue-sea !important;
      }
    }
  }
}

.leaflet-right {
  .leaflet-bar.leaflet-control {
    position: absolute !important;
    right: 1px;
    top: 93px !important;
    margin: 0 16px 0 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;

    @include breakpoint(md) {
      top: 190px !important;
    }

    .polyline-measure-clearControl {
      @include icomoon('\e901');
      text-indent: -9999px;
      overflow: hidden;

      position: absolute;
      left: -41px;
      top: 0;
      width: 38px !important;
      height: 38px !important;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      color: $white !important;
      background: $blue-sea !important;
      display: none !important;

      &:before {
        font-size: 12px;
        line-height: 38px;
        text-indent: 0;
        display: block;
      }
    }
  }

  #polyline-measure-control {
    @include icomoon('\e90f');
    text-indent: -9999px;
    overflow: hidden;
    width: 38px !important;
    height: 38px !important;
    color: var(--title) !important;

    &:before {
      font-size: 22px;
      line-height: 38px;
      text-indent: 0;
      display: block;
    }

    &:hover {
      background: var(--component-background) !important;
      color: $blue-sea !important;
    }

    &.polyline-measure-controlOnBgColor {
      color: $blue-sea !important;

      ~ .polyline-measure-clearControl {
        display: block !important;
      }
    }
  }
}

.slick-track {
  min-width: 100%;
}
