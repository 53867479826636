@import 'src/styles/variables';

.GoBackButton {
  &.ant-btn-primary {
    font-size: 1.125rem;
  }

  &.ant-btn-text {
    padding: 0;
    color: var(--title);
  }
}
