@import "src/styles/variables";
@import "src/styles/mixins";

.ServiceMap {
  height: 180px;
  min-width: 310px;
  margin: 8px 0 0;

  @include breakpoint(xlg) {
    margin-right: -20px;
  }

  @include breakpoint(md) {
    min-width: 258px;
    height: 140px;
  }

  @include breakpoint(md) {
    width: calc(100% + 40px);
    height: 180px;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: 50px;
  }

  &__content {
    background-color: lightgrey;
    height: 100%;
    position: relative;

    .leaflet-container {
      height: 100%;
    }
  }
}
