.ModalPhoto {
  .ant-modal {
    padding: 0;
  }
  .ant-modal-content {
    background-color: transparent;
  }
  
  &__close {
    padding: 5px;
  }
  
  &__container {
    height: 100vh;
    width: 100vw;
    padding: 3rem 2.5rem;
    align-items: center;
    justify-content: center;
  }
  
  &__img {
    max-height: 100%;
    max-width: 100%;
  }
}
