@import 'src/styles/variables';

.ServiceRegistration {
  &__form {
    text-align: right;
  }

  &__submit.ant-btn.app-btn-green {
    text-transform: uppercase;
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }
}
