@import 'src/styles/variables';

.ProviderInfo {
  padding: 44px 0 0;
  position: relative;

  &__site {
    margin-bottom: 21px;
    margin-right: 80px;

    a {
      color: var(--title);
      text-decoration: none;
      word-break: break-all;
    }
  }

  &__name {
    font: bold 32px/42px $font-ttl;
    margin: 0 0 10px;
  }

  &__btnEdit {
    position: absolute;
    right: 0;
  }

  p {
    font-size: 15px;

    &:first-of-type {
      color: var(--title);
    }
  }
}
