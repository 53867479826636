.ant-select-open {
  .ant-select-selection {
    box-shadow: none;
  }
}

.ant-select-selection {
  border-color: $grey-light;
  border-radius: 4px;
}

.ant-select-selection--multiple {
  .ant-select-selection__rendered {
    margin-left: 3px;
  }

  .ant-select-selection__choice {
    border-radius: 4px;
    border-color: $grey-light;
    background: rgba(231, 231, 230, 0.2);
    padding-left: 6px;
    color: $text-color;
  }

  .ant-select-selection__choice__remove {
    font-size: 10px;
    color: $alto;
  }
}
