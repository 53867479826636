@import 'src/styles/variables';
@import 'src/styles/mixins';

.ProductArticle {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include breakpoint(xlg) {
    padding-top: 0.75rem;
  }

  &__summary {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4;
    color: $black;
  }

  &__frame {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
    height: auto;
  }

  &__description {
    margin-left: auto;
    max-width: 640px;
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  &__body {
    max-width: 540px;

    h2,
    h3,
    h4 {
      margin: 1.5rem 0;
      font-weight: bold;
    }

    img {
      width: 100%;
      cursor: pointer;
    }
  }
}
