@import "src/styles/variables.scss";

.Confirmation {
  
  
  &__content {
    max-width: 300px;
  }
  
  &__icon {
    //color: $blue-sea;
  }

}
