@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.VesselInfo {
  padding-right: 10%;

  @include breakpoint(md) {
    padding-right: 0;
  }

  &__type {
    font-size: 14px;
    line-height: 1.8;
    color: var(--title);
    margin-bottom: 20px;
  }

  &__table {
    margin: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 14px;
    line-height: 1.8;
    text-align: left;

    th,
    td {
      padding: 0 10px 0 0;
    }

    th {
      font-weight: normal;
      color: $grey-medium-2;
    }
  }
}
