@import "src/styles/variables";
@import "src/styles/mixins";


.ArticleEditorControls {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  position: relative;

  @include breakpoint(sm) {
    display: block;
    padding: 30px 0 0;
    margin-top: -20px;
  }

  .ant-btn {
    margin: 0 10px;
    text-transform: uppercase;

    &.app-btn-min-w-170 {
      @include breakpoint(sm) {
        margin: 15px 0;
        width: 100%;
      }
    }
  }

  &__cancel {
    top: 50%;
    right: 0;
    margin: -20px 0 0 !important;

    @include breakpoint(sm) {
      top: 17px;
    }
  }

}
