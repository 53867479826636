@import 'src/styles/variables';

.FleetsEditorItem {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  &__text {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
  }

  .ant-btn-link {
    width: 38px;
    min-width: 38px;
    padding: 0;
    font-size: 16px;
    color: $grey-medium-2;

    &:active,
    &:focus,
    &:hover {
      color: $blue-sea;
    }
  }

  &__popover {
    display: flex;
    justify-content: flex-end;
  }

  .EditableContent .ant-input[disabled] {
    border-color: transparent !important;
  }
}
