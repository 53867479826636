// colors
$text-color: #4e5b6a;

$black-dark: #000;
$black: #020814;

$grey-dark: rgba(0, 0, 0, 0.6);
$grey-medium: #979797;
$grey-medium-2: #979693;
$silver: #c4c4c4;
$grey-light: #e7e7e6;
$grey-x-light: #f2f2f2;

$blue-sea: #4e74fc;
$blue-sea-dark: #4262d6;
$border-hover: #789aff;
$blue-xx-dark: #071c2c;
$alto: #dadada;

//assigned colors
$header-black: $black;

$blue: rgba(2, 8, 20, 0.9);

$beige: #f8e3d3;
$light-blue: #0b2e49;
$grey: #d3d3d3;
$light-grey: #e8e8e8;
$red: #ff0000;
$error: #f5222d;
$white: #fff;

$primary: $blue;
$primary-invert: invert($blue);
$text: $blue;

$color-green: yellowgreen;
$color-green-light: #00e06a;
$color-warning: #ffdb9a;

$btn-default-color: #696a6c;
$filled-star-color: #ffdc24;

$font-inter: 'Inter',
  // add default font
  Arial,
  sans-serif !important;
$font-ttl: 'Titillium', Arial, sans-serif !important;

$breakpoints: (
  // Extra small screen / phone
  'xs': 0,
  // Small screen / phone
  'sm': 576px,
  // Medium screen / tablet
  'md': 768px,
  // Large screen / desktop
  'lg': 992px,
  // Large / Extra large screen
  'xlg': 1024px,
  // Extra large screen / wide desktop
  'xl': 1200px,
  'xxl': 1540px
) !default;
