@import 'src/styles/variables';
@import 'src/styles/mixins';

.PromotionControl {
  @include breakpoint(md-up) {
    padding-left: 220px;
  }

  .LoaderOverlay {
    min-height: 90px;
  }

  .Loading {
    background-color: rgba(230, 230, 230, 0.5);
  }
}
