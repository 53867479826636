@import 'src/styles/variables.scss';

.CloseButton.ant-btn {
  position: absolute;
  color: var(--button-close);
  padding: 0;
  border: 0;

  &:focus,
  &:active,
  &:hover {
    background: none;
    opacity: 0.7;
  }
}
