@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.FishingLicence {
  width: 100%;
  table-layout: fixed;
  color: var(--title);
  min-width: 728px;

  table {
    width: 100%;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  th,
  td {
    text-align: left;
    overflow: hidden;
    padding: 13px 10px;
    vertical-align: top;
    font-size: 16px;

    @include breakpoint(md) {
      padding-left: 0;
    }

    &:nth-child(1) {
      width: 15.58%;
    }
    &:nth-child(2) {
      width: 22.14%;
    }
    &:nth-child(3) {
      width: 22.14%;
    }
    &:nth-child(4) {
      width: 22.14%;
    }
    &:nth-child(5) {
      width: 18%;
    }
  }

  th {
    border-bottom: 1px solid #c4c4c4;
    font-weight: 600;
    text-transform: uppercase;
  }

  > tbody {
    > tr {
      &:hover {
        > td {
          background: rgba(196, 196, 196, 0.2);
        }
      }

      > td {
        &:nth-child(1) {
          padding: 0;
          width: 82%;
        }

        &:nth-child(2) {
          width: 18%;
        }
      }
    }
  }

  &__scrollVertical {
    height: 400px;
    overflow-y: auto;
    width: calc(100% + 1rem);
    min-width: calc(728px + 1rem);
  }

  &__scrollHorizontal {
    overflow-x: auto;
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &__touchDevice {
    padding-right: 0;
    margin-right: 0;

    .FishingLicence__scrollVertical {
      height: 300px;
      width: 100%;
      min-width: 728px;
    }
  }
}
