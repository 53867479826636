@import "src/styles/variables";

.CategorySelector {
  &__title {
    margin: 1rem 0 0.5rem;
    color: dimgrey;
    font-family: $font-ttl;
    font-weight: 700;
  }

  &__label {
    display: inline-block;
    padding-left: 0;
    margin-right: 1rem;
    font-family: $font-ttl;
    font-weight: 600;
    color: grey;
  }

  &__checkbox {
    margin-right: 0.25rem;
  }

  .ant-btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
