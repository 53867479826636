@import 'variables';
@import 'mixins';

.ant-collapse {
  > .ant-collapse-item {
    &.ant-collapse-item-active {
      > .ant-collapse-header {
        .icon-custom {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    > .ant-collapse-header {
      padding-left: 0 !important;
      padding-right: 40px !important;

      .icon-custom {
        @include transition;
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 22px;
        font-weight: bold;
        transform: translateY(-50%);
      }
    }
  }

  &.FilterCollapse {
    border: 0;
    border-bottom: 1px solid var(--button-border-color);
    padding-bottom: 16px;
    background: none;

    > .ant-collapse-item {
      border: 0;

      &.ant-collapse-item-active {
        > .ant-collapse-header {
          .icon-custom {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }

      > .ant-collapse-header {
        padding: 0 0 2px 20px !important;
        font: bold 14px/21px $font-ttl;
        text-transform: uppercase;

        .icon-custom {
          left: 0;
          right: auto;
          font-size: 18px;
        }
      }
    }

    .ant-collapse-content {
      border: 0;

      > .ant-collapse-content-box {
        padding: 10px 16px 10px 10px;
      }
    }

    .ant-slider-dot {
      display: none;
    }
  }

  &.ant-collapse-borderless {
    background: $white;
  }
}
