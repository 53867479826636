@import 'src/styles/variables';
@import 'src/styles/mixins';

.ExpanderHeader {
  &__title {
    font: bold 26px/1.55 $font-ttl;
    text-transform: uppercase;
    margin: 0;
  }

  &__header {
    .ant-btn {
      span {
        @include breakpoint(sm) {
          display: none;
        }
      }
    }
  }
}

.Expander2,
.Expander {
  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        padding: 5px 40px 5px 0;
      }
    }
  }
}
