.AnnualReports {
  user-select: none;

  &__header {
    font-weight: bold;
  }

  td:not(:first-child) {
    text-align: right;
  }

  .app-info-table-col-w50,
  .account-group-name,
  .app-info-table-head {
    color: var(--table-text);
  }

  .app-info-table-align-center {
    color: var(--title);
  }
}

.account-group-name {
  text-transform: uppercase;
  font-weight: bold;
}

.account-name-calculated {
  font-weight: bold;
}
