@import 'src/styles/mixins';
@import 'src/styles/variables';

.Header {
  padding: 0 26px;
  background: $header-black;
  min-height: 66px;
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include breakpoint(md) {
    padding-right: 8px;
  }

  .app-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__search {
    @include breakpoint(md-down) {
      order: 1;
    }
  }

  &__logo {
    @include breakpoint(md-down) {
      order: 2;
    }
  }

  &__accountLink {
    @include transition;
    color: $white !important;
    text-align: end;
    font-size: 14px;

    @include breakpoint(xlg-down) {
      display: none;
    }
  }

  &__burger {
    padding: 7px;
    margin-right: -7px;
    display: flex;
    position: relative;
    left: -13px;
    top: 1px;

    @include breakpoint(xlg-up) {
      display: none;
    }
  }
}
