@import 'src/styles/variables';

.BrandLogo {
  position: absolute;
  top: 6px;
  left: -6px;
  border: 1px solid $grey-light;
  background: $white;
  box-sizing: border-box;
  border-radius: 2px;
  max-height: 50px;
  max-width: 100px;
  display: block;

  &:hover {
    opacity: 1 !important;
  }

  &__image {
    max-height: 48px;
    max-width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
    box-shadow: -2px 2px 5px 0 #d7d7d7;
    width: 100%;
    height: 100%;
  }
}
