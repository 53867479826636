@import 'src/styles/variables';
@import 'src/styles/mixins';

.PromotionCost {
  margin: 0 auto;
  padding: 50px 0 20px;
  text-align: center;

  @include breakpoint(sm-up) {
    max-width: 340px;
  }

  &__cost {
    height: 150px; // mustn't change height on the props change
  }

  &__total {
    display: block;
    font-weight: bold;
    font-size: 38px;
    color: var(--title);
    animation: fadeIn 0.5s;
  }

  &__rate {
    font-size: 14px;
    animation: fadeIn 0.5s;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 0;
  }
}
