@import 'src/styles/variables';
@import 'src/styles/mixins';

.Main {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &--ios {
    height: -webkit-fill-available;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: auto;
    width: 100%;
    overflow-y: auto;
  }

  &__holder {
    display: flex;
    flex: 1;
    height: 100%;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: auto;
    max-width: 1298px;
    width: 100%;
    padding: 0 20px 0 270px;
    position: static !important;

    @include breakpoint(xxl-up) {
      margin: 0 auto;
      max-width: 100%;
    }

    @include breakpoint(xl) {
      padding-left: 227px;
    }

    @include breakpoint(xlg) {
      padding-left: 20px;
    }

    &--narrow {
      max-width: 844px;
      width: 100%;

      @include breakpoint(xlg) {
        margin: 0 auto;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 1.5rem;
    position: relative;

    @include breakpoint(xlg) {
      padding: 0.75rem 0 1.25rem;
    }

    &.app-justify-content-center {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__headerTitle {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0;

    @include breakpoint(md) {
      font-size: 24px;
    }
  }

  &__wideLayout {
    .app-container {
      max-width: inherit;
    }

    &.Main__hiddenAside {
      .Main__content {
        padding: 0;
      }

      .Main__aside {
        @include breakpoint(xxl-up) {
          display: none;
        }
      }
    }

    .Main__content {
      padding: 0 0 0 270px;
      max-width: inherit;

      @include breakpoint(xl) {
        padding-left: 227px;
      }

      @include breakpoint(xlg) {
        padding: 0 20px;
      }
    }

    .Aside {
      left: 0;
    }
  }

  &__hiddenAside {
    .Aside {
      @include breakpoint(xlg-up) {
        display: none;
      }
    }
  }

  &__home {
    .CategoryList {
      padding: 40px 20px 55px;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Main__container {
    display: block;
  }
}
