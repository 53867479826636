@import 'src/styles/variables';
@import 'src/styles/mixins';

.ArticleEditorHeader {
  &__cancel {
    right: 0;
    top: 32px;

    @include breakpoint(xlg) {
      top: 13px;
    }

    @include breakpoint(md) {
      top: 8px;
    }
  }
}
