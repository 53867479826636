@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.OwnersItem {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    @include breakpoint(md) {
      display: block;
    }
  }

  &__title {
    font-weight: bold;
    color: var(--title);

    @include breakpoint(md) {
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;

    @include breakpoint(md) {
      padding-top: 3px;
      flex-direction: column-reverse;
    }

    .TimeSlot {
      min-width: 200px;
      margin-right: 10px;

      @include breakpoint(md) {
        margin-bottom: 10px;
      }
    }
  }
}
