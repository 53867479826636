.FBShareButton {
  margin-bottom: 16px;

  .ant-btn {
    line-height: 24px;
    height: 25px;
    padding: 0 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .icon-custom {
    margin-right: 7px;
    font-size: 16px;
    position: relative;
    top: -1px;
  }
}
