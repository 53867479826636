@import 'src/styles/variables';

.company-data {
  line-height: 1.8;
  color: var(--title);

  th,
  td {
    vertical-align: top;
  }

  td:first-child {
    min-width: 160px;
    padding-right: 15px;
    color: $grey-medium-2;
  }

  .link-underlined {
    text-decoration: underline;
  }
}
