@import 'src/styles/variables';
@import 'src/styles/mixins';

.ant-btn.EventButton {
  height: 36px;
  line-height: 36px;
  color: white;
  background-color: $color-green-light;
  font-size: 18px;
  position: absolute;
  top: 8px;
  right: 0;
  min-width: 150px;

  @include breakpoint(sm) {
    min-width: auto;
    font-size: 14px;
    top: 20px;
    padding: 0 10px;
  }
}
