@import 'src/styles/variables';
@import 'src/styles/mixins';

.EventEditor {
  padding-bottom: 4rem;

  @include breakpoint(md) {
    padding-bottom: 30px;
  }

  @include breakpoint(sm) {
    padding-bottom: 15px;
  }

  &__holder {
    padding-left: 220px;

    @include breakpoint(md) {
      padding-top: 30px;
      padding-left: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 30px;
  }

  .anticon {
    color: $grey;
  }
}
