@import 'src/styles/variables';
@import 'src/styles/mixins';

.MapLegend {
  &__btn.ant-btn {
    height: 58px;
    width: 38px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0;
    background-color: var(--body-background);
    border: 1px solid var(--button-map-border) !important;
  }

  &__legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
  }

  &__vessel {
    display: inline-flex;
    min-width: 50%;
    font-size: 13px;
  }

  &__provider {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 1px solid #000;

    span {
      display: block;
      border: 2px solid $white;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }

  &__label {
    line-height: 1;
    padding: 0 0 0 10px;
  }

  &__list {
    padding: 20px 10px 20px 30px;

    .ShipIcon {
      left: 0;
    }
  }

  &__content {
    padding: 0;
  }
}
