.FleetsEditor {
  &__placeholder {
    text-align: center;
    margin-bottom: -20px;
  }

  &__invocation {
    font-size: 16px;
    line-height: 38px;
    color: #C4C4C4;
  }
}
