@import 'src/styles/variables';
@import 'src/styles/mixins';

.ServiceCard {
  max-width: 720px;

  &:before {
    padding-top: 23% !important;

    @include breakpoint(sm) {
      padding-top: 83% !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 2.875rem;
    padding-bottom: 1.5rem;
    position: relative;
    height: 100%;

    @include breakpoint(sm) {
      flex-direction: column;
      margin: 0 1.25rem;
    }
  }

  &__banner {
    width: 46%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-items: center;

    @include breakpoint(sm) {
      width: 100%;
      height: 90px;
    }

    &:hover {
      opacity: 1 !important;
    }

    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  &__text {
    width: 49.5%;
    overflow: hidden;
    margin: 0.5rem 0;
    position: relative;

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  &__name {
    font-size: 1.375rem;
    font-weight: 700;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  &__description {
    font-size: 0.875rem;
    color: var(--text);
    position: absolute;
    bottom: 0;

    @include multiLineEllipsis(
      $lineHeight: 20px,
      $lineCount: 4,
      $bgColor: var(--body-background)
    );

    @media (max-width: 567px) {
      @include multiLineEllipsis(
        $lineHeight: 18px,
        $lineCount: 4,
        $bgColor: var(--body-background)
      );
    }
  }
}
