.react-tel-input {
  .form-control {
    background-color: var(--body-background);
    border-color: var(--button-border-color);
  }
  .flag-dropdown.open,
  .flag-dropdown.open .selected-flag,
  .flag-dropdown {
    background-color: var(--component-background);
    border-color: var(--button-border-color);
  }
  .selected-flag:hover,
  .selected-flag:active {
    background-color: var(--select-item-active-bg);
  }

  .country-list {
    background-color: var(--body-background);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .country:hover,
    .country.highlight,
    .country:active {
      background-color: var(--select-item-active-bg);
      border-color: var(--button-border-color);
    }
  }
}
