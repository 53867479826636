.ContactEditor {
  .CardWrapper {
    max-width: 278px;
    min-width: 278px;
    padding-bottom: 0;
  }

  .ContactCard {
    min-width: 100%;
    min-height: 150px;
    padding: 10px 60px 20px 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
}
