@import 'src/styles/variables';
@import 'src/styles/mixins';

.VesselCardWrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &:hover {
    .VesselCard__photo {
      a {
        &:after {
          display: block;
        }
      }
    }

    .VesselCardWrapper__compare {
      display: block;
    }
  }

  &__compareMsg {
    position: relative;
  }

  &__compare {
    display: none;
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    font-size: 13px;
    padding: 12px 8px;
    font-weight: 500;
    animation: fadeIn 0.3s;
    color: $white;

    &--chosen {
      display: block;
    }

    input {
      width: 15px;
      height: 15px;
      position: relative;
      top: 3px;
      margin-right: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__fav {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 1000;
    font-size: 24px;
    line-height: 1;

    .icon-star-filled {
      color: $filled-star-color;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
