@import 'src/styles/variables';
@import 'src/styles/mixins';

.ArticleBanner {
  position: relative;
  margin-bottom: 3rem;
  width: 100%;

  &__actions {
    background-color: var(--component-background);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }

  &__button {
    text-align: left !important;
  }

  &__divider {
    height: 1px;
    border-top: 1px solid var(--button-border-color);
  }
}
