@import '../../../styles/mixins';
@import '../../../styles/variables';

.CountriesSelect {
  min-width: 200px;

  @include breakpoint(sm) {
    padding: 1rem 0;
    max-width: 100%;
  }

  .ant-select-selection {
    max-height: 45px;
    overflow: hidden;
  }

  .ant-select {
    width: 100%;
  }
}
