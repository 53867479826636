@import 'src/styles/mixins';
@import 'src/styles/variables';

.Video {
  padding: 84px 20px;
  background: #072F61;

  @include breakpoint(lg) {
    padding: 45px 20px;
  }

  &__holder {
    max-width: 860px;
    margin: 0 auto;
  }
}
