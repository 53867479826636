.ThemeToggle {
  position: absolute;
  bottom: 60px;
  margin-top: 40px;
  display: flex;
  align-items: center;

  @media screen and (max-height: 700px) {
    position: static;
  }

  .icon-custom {
    font-size: 30px;
    margin-right: 10px;
  }
}
