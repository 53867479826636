.MapLink {
  padding: 1.25rem 20px 0.5rem;
  background-color: var(--component-inactive-background);
  margin: 0 -20px 0.75rem;

  .CategoryList {
    &__item {
      &:before {
        padding-top: 26%;
      }
    }
  }
}
