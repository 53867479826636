@import 'src/styles/variables';
@import 'src/styles/mixins';

.NewsListWrapper {
}

.NewsList {
  height: 100% !important;
  width: 100% !important;

  &__scroller {
    margin: 0 -20px;
    padding: 0 20px;
  }

  &__loader {
    width: 100%;
    height: 70px;
  }

  &__row {
    margin: 0 -0.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__campaign {
    background-color: #4e74fc;
    margin-right: 1rem;
  }

  &__header {
    display: flex;

    @media screen and (max-width: 380px) {
      .Main__headerTitle {
        margin-right: auto;
      }

      flex-direction: column;
    }
  }

  &__controls {
    display: flex;

    @media screen and (max-width: 380px) {
      margin-top: 1rem;
      justify-content: space-between;
      width: 100%;
    }
  }
}
