@import "src/styles/variables";
@import "src/styles/mixins";

.PostInfo {
  color: $grey-medium;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @include breakpoint(sm) {
    display: flex;
  }

  &__issuer {
    font-size: 0.75rem;
    font-weight: normal;
    font-family: $font-inter;
    margin: 0;
    color: $grey-medium;
    display: inline;
  }

  &__date {
    font-size: 0.75rem;
    font-style: normal;
  }

  a {
    @include transition;
    color: $grey-medium;
    text-decoration: underline;

    &:hover {
      color: $blue-sea;
      opacity: 1;
    }
  }
}