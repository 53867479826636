.CardWrapper {
  position: relative;
  padding-right: 18px;
  display: inline-flex;
  padding-bottom: 32px;

  &__menu {
    position: absolute;
    top: 0;
    right: 18px;
    z-index: 1;
  }

  .VideoCard,
  .ProductCard {
    margin: 0;
  }
}
