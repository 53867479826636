@import 'src/styles/variables';

.MapFilter {
  &__button {
    &.ant-btn {
      height: 58px;
      width: 38px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      padding: 0;
      background-color: var(--body-background);
      border: 1px solid var(--button-map-border) !important;
    }

    &-content {
      position: relative;
      margin-top: 10px;
    }
  }

  &__indicator {
    position: absolute;
    background-color: $blue-sea;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: -3px;
    right: 7px;
  }
}
