@import 'src/styles/variables';
@import 'src/styles/mixins';

.VesselFilter {
  position: relative;
  display: flex;
  flex-direction: column;

  &__banner {
    padding: 0 0 16px;
    border-bottom: 1px solid var(--button-border-color);
  }

  &__row {
    padding: 10px 0 14px;
    &--bordered {
      border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    }
  }

  &__label--bold,
  &__label {
    font-family: $font-ttl;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 8px;
    color: var(--title);

    &--bold {
      font-weight: bold;
    }
  }
}
