@import 'src/styles/variables';
@import 'src/styles/mixins';

.CommonBanner {
  flex: 1;
  display: block;

  &__figure {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &--draft {
      opacity: 0.65;
    }
  }
}
