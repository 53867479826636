@import 'src/styles/variables';
@import 'src/styles/mixins';

.slideout {
  position: fixed;
  top: 180px;
  transition-duration: 0.3s;
  z-index: 1000;

  @media screen and (min-width: 768px) {
    top: 180px;
  }

  .slider {
    @include transition;
    font-size: 20px;
    padding: 3px 0.5rem;
    display: inline-block;
    vertical-align: top;
    background: var(--body-background);
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;

    &:hover {
      color: $blue-sea;
    }
  }

  .Aside {
    max-width: 100%;
  }
}

.slideout-bottom {
  bottom: 0;
  top: auto;
  left: 65px;
  padding: 0.5rem 0.25rem 20px;
  border-radius: 5px 5px 0 0;
  border-width: 1px 1px 0 1px;

  .slider {
    border-radius: 20px;
    min-width: 58px;
    text-align: center;
    color: var(--text-active);

    .icon-custom {
      vertical-align: -2px;
    }
  }
}

.slideout-left {
  left: 0;

  .slider {
    border-radius: 0 5px 5px 0;
    border-width: 1px 1px 1px 0;
  }
}

.slideout-right {
  right: 0;

  .slider {
    border-radius: 5px 0 0 5px;
    border-width: 1px 0 1px 1px;
  }
}

.slideout-inner {
  position: fixed;
  transition-duration: 0.3s;
  background: $white;
  border-color: $grey;
  border-style: solid;
}

.slideout-inner-bottom {
  bottom: 0;
  width: 40vw;
  padding: 0;
  border-width: 0;
  background-color: transparent;

  @include breakpoint(md) {
    width: 100vw;
  }
}

.slideout-inner-left {
  left: -270px;
  top: 140px;
  width: 132px;
  padding-left: 30px;
  border-width: 1px 0;
}

.slideout-inner-right {
  display: flex;
  right: -270px;
  width: 270px;
  border-width: 1px;
}
