@import 'src/styles/variables';

.ContactCard {
  padding: 0 60px 0 0;
  position: relative;
  min-width: 200px;

  &__photo {
    width: 118px;
    height: 118px;
    border: 6px solid $grey-light;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    img {
      width: 100%;
    }
  }

  &__info {
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;
    line-height: 1.5;
    color: $grey-medium;
    font-size: 15px;

    .ContactCard__name {
      font: bold 1.25rem $font-ttl;
      color: var(--title);
    }

    li,
    a {
      color: var(--title);

      &[href^='mailto'] {
        word-break: break-all;
      }
    }
  }

  &__position {
    margin-bottom: 10px;
    color: $grey-medium !important;
  }
}
