@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.EventArticle {
  overflow: hidden;
  position: relative;
  width: 100%;

  &__description {
    margin: 0 0 60px 76px;
    line-height: 1.6;

    @include breakpoint(lg) {
      margin-left: 0;
    }

    @include breakpoint(sm) {
      margin-bottom: 17px;
    }
  }

  &__body {
    @include breakpoint(md) {
      margin-left: 76px;
    }
  
    h2,
    h3,
    h4 {
      margin: 1.5rem 0;
      font-weight: bold;
    }
  
    img {
      width: 100%;
      cursor: pointer;
    }
  
    .ql-video {
      width: 540px;
      height: 310px;
    }
  }
}
