.VesselTitle {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  text-transform: uppercase;
  margin: 0 0 14px;

  &__flag {
    width: 28px;
    position: relative;
    top: 1px;
    margin-right: 10px !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);

    img {
      display: block;
      width: 100%;
    }
  }
}