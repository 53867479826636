.LoaderOverlay {
  width: 100%;
  height: 100%;
  min-height: 480px;
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
  }
}
