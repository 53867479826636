.ant-btn {
  &.ant-btn-translucent {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  &.app-btn-link-custom {
    border-radius: 30px;

    &:hover {
      background: $blue-sea;
      color: $white;
      border-color: $blue-sea;
    }

    &:active,
    &:focus {
      background: $blue-sea-dark;
      color: $white;
      border-color: $blue-sea-dark;
    }
  }

  .icon-custom {
    &.icon-plus {
      font-size: 18px;
      margin-right: 8px;
    }
  }
}

.app-ant-btn-circle {
  &.ant-btn-circle {
    width: 36px;
    padding-left: 0;
    padding-right: 0;
  }
}

a {
  &.ant-btn.ant-btn-lg {
    line-height: inherit;
  }
}
