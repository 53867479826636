@import 'src/styles/variables';

.FleetsMenu {
  .Main__header {
    padding-bottom: 10px;
  }
  &__subheader {
    text-align: center;
    font: 14px/17px $font-inter;
    color: var(--text);
    margin-bottom: 20px;
  }
}
