.ShipIcon {
  height: 5px;
  width: 9px;
  left: 2px;
  top: 4px;

  @media screen and (max-width: 576px) {
    height: 11px;
    width: 18px;
    left: -2px;
    top: 0;
  }

  &__left,
  &__right {
    width: 9px;
    height: 3px;
    border: solid #bfbfbf;

    @media screen and (max-width: 576px) {
      width: 13px;
      height: 5px;
    }

  }

  &__left {
    transform: skew(-55deg);
    border-width: 1px 1px 0;
  }

  &__right {
    transform: skew(55deg);
    border-width: 0 1px 1px;
  }
}
