.ant-modal {
  &.modal-small {
    width: 414px !important;

    .ant-modal-body {
      padding: 16px !important;
    }
  }

  .Main__headerTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 1.3;
    text-align: center;
  }

  .Main__header {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .CloseButton {
    top: -6px;
    right: 0;
  }
}
