@import 'src/styles/variables';

.ant-picker {
  .ant-picker-suffix {
    color: $grey-medium-2;
  }
}

.ant-picker-date-panel {
  .ant-picker-content {
    .ant-picker-cell {
      color: $grey-medium-2;

      &-in-view {
        color: var(--text);
      }

      &:hover {
        .ant-picker-cell-inner {
          background-color: var(--cell-disabled-background) !important;
          color: var(--title) !important;
        }
      }

      &-disabled {
        .ant-picker-cell-inner {
          color: $grey-medium-2 !important;
        }

        &:before {
          background-color: var(--cell-disabled-background);
        }
      }
    }
  }

  .ant-picker-header {
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
      color: $grey-medium-2;

      &:hover {
        color: $white !important;
      }
    }
  }
}
