@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.UsersItem {
  margin-bottom: 11px;

  @include breakpoint(md) {
    margin-bottom: 27px;
  }
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(md) {
      display: block;
    }
  }

  &__title {
    font-weight: bold;
    color: $black-dark;
    word-break: break-all;

    @include breakpoint(md) {
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;

    @include breakpoint(md) {
      padding-top: 3px;
      flex-direction: column-reverse;
    }

    .TimeSlot {
      min-width: 200px;
      margin-right: 10px;

      @include breakpoint(md) {
        margin-bottom: 10px;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 44px;
    padding-top: 11px;

    @include breakpoint(md) {
      display: block;
    }
  
    .ant-btn {
      margin-left: 5px;
      min-width: 140px;

      @include breakpoint(md) {
        margin-left: 0;
        margin-bottom: 5px;
      }
    }
  
    .ant-btn-link {
      color: $grey-medium;
    }
  }
}
