.ant-tag {
  &.app-custom-tag {
    min-width: 140px;
    border-radius: 50px;
    border: 1px solid var(--button-border-color);
    line-height: 30px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding: 0 24px;
    margin-right: 0;

    &.ant-tag-green {
      background: none;
      color: $color-green-light;
    }

    &.ant-tag-blue {
      background: #f7f8ff;
      color: $blue-sea;
    }
  }

  .anticon {
    vertical-align: 1px;
  }
}
