@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.EventTitle {
  display: flex;
  padding: 0 0 37px;

  @include breakpoint(sm) {
    padding-bottom: 17px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.2;
    color: var(--title);
    margin: -3px 0 8px;
  }

  &__location {
    margin-bottom: 8px;
    color: $grey-medium;
  }
}
