@import 'src/styles/variables';
@import 'src/styles/mixins';

.CompanyFleet {
  &__header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .VesselPage {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 28px;
  }

  .VesselCardWrapper {
    width: 25%;

    &:hover {
      .VesselCardWrapper__title {
        display: none;
      }
    }

    @include breakpoint(lg) {
      width: 33.33%;
    }

    @include breakpoint(md) {
      width: 50%;
    }

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  .VesselCard {
    &:hover {
      .VesselCard__title {
        display: none;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__inside {
      margin: 0 8px;
    }

    &__photo a:after {
      background: transparent;
    }

    &__fav:hover {
      cursor: default;
    }
  }

  &__btn {
    .icon-custom {
      font-size: 22px;
      color: $alto;
      margin-right: 8px;
    }
  }
}
