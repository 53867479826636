@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.Navigation {
  margin-top: 25px;
  list-style: none;
  padding: 0;

  @media screen and (max-height: 400px) {
    margin: 0;
  }

  &--active {
    color: $black !important;
    font-weight: 600;
  }

  &__accountLinkItem {
    margin-bottom: 20px;

    @include breakpoint(xlg-up) {
      display: none;
    }
  }

  li {
    padding-top: 13px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__link {
    color: $grey-medium !important;

    &--active {
      color: var(--text-active) !important;
      font-weight: 600;
    }
  }
}
