.DraftOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &__label {
    position: absolute;
    bottom: 12px;
    right: 12px;
    background: rgba(196, 196, 196, 0.4);
    text-transform: uppercase;
    font-size: 15px;
    color: white;
    height: 32px;
    border: 1px solid rgba(242, 242, 242, 0.6);
    padding: 4px 24px;
    border-radius: 16px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.25);
  }
}
