.NewsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4rem;
  margin: 0 -12px;

  &__banner {
    padding: 0 0.75rem 1.5rem;
    width: 100%;
  }
}
