@import 'src/styles/mixins';
@import 'src/styles/variables';

.Intro {
  background: url('/assets/home/bg-intro@2x.jpg') center / cover no-repeat;
  //background: url('./assets/home/bg-intro@2x.jpg') center / cover no-repeat;
  color: $white;
  position: relative;

  @include breakpoint(xl) {
    background-image: url('/assets/home/bg-intro-tablet@2x.jpg');
  }

  @include breakpoint(md) {
    background-image: url('/assets/home/bg-intro-mobile@2x.jpg');
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180.76deg,
      rgba(20, 31, 61, 0.7) 9.51%,
      rgba(20, 31, 61, 0) 98.05%
    );
  }

  &__main {
    overflow: hidden;
  }

  &__holder {
    display: flex;
    align-items: center;
    padding: 50px 93px;
    min-height: 606px;
    height: calc(100vh - 66px);
    position: relative;
    z-index: 2;

    @include breakpoint(xl) {
      min-height: 520px;
    }

    @include breakpoint(lg) {
      padding-left: 56px;
      padding-right: 56px;
    }

    @include breakpoint(md) {
      min-height: 380px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__col {
    text-align: center;
    max-width: 760px;
    margin: 0 auto;

    @include breakpoint(lg) {
      max-width: 540px;
    }

    @include breakpoint(xxl-up) {
      transform: scale(1.5);
    }
  }

  &__btns {
    display: flex;
    justify-content: center;

    @include breakpoint(md) {
      display: block;
    }

    a {
      margin: 0 23px;

      @include breakpoint(md) {
        display: block;
        margin: 0 auto 25px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 50px;

    @include breakpoint(md) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .Logo {
    margin-bottom: 41px;

    @include breakpoint(md) {
      margin-bottom: 11px;
    }

    img {
      width: 270px;

      @include breakpoint(md) {
        width: 200px;
      }
    }
  }
}
