@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.woff?j7xwmb') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
  content: '\e91e';
}
.icon-chevron-up:before {
  content: '\e922';
}
.icon-day-night:before {
  content: '\e923';
  color: #c4c4c4;
}
.icon-facebook:before {
  content: '\e91d';
}
.icon-info:before {
  content: '\e91f';
}
.icon-linkedin-blue:before {
  content: '\e920';
  color: #4e74fc;
}
.icon-twitter-blue:before {
  content: '\e921';
  color: #4e74fc;
}
.icon-seabed-2:before {
  content: '\e91c';
}
.icon-weather:before {
  content: '\e91b';
}
.icon-delete:before {
  content: '\e919';
}
.icon-pencil-create:before {
  content: '\e91a';
}
.icon-map-point:before {
  content: '\e916';
}
.icon-star:before {
  content: '\e917';
}
.icon-star-filled:before {
  content: '\e918';
}
.icon-bell:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e901';
}
.icon-currents:before {
  content: '\e902';
}
.icon-down:before {
  content: '\e903';
}
.icon-ellipsis:before {
  content: '\e904';
}
.icon-filters:before {
  content: '\e905';
}
.icon-map-info:before {
  content: '\e906';
}
.icon-maximize:before {
  content: '\e907';
}
.icon-minimize:before {
  content: '\e908';
}
.icon-minus:before {
  content: '\e909';
}
.icon-moon:before {
  content: '\e90a';
}
.icon-photo:before {
  content: '\e90b';
}
.icon-plus:before {
  content: '\e90c';
}
.icon-radar:before {
  content: '\e90d';
}
.icon-route:before {
  content: '\e90e';
}
.icon-ruller:before {
  content: '\e90f';
}
.icon-seabed:before {
  content: '\e910';
}
.icon-search:before {
  content: '\e911';
}
.icon-sun:before {
  content: '\e912';
}
.icon-thermometer:before {
  content: '\e913';
}
.icon-water-waves:before {
  content: '\e914';
}
.icon-wind-sign:before {
  content: '\e915';
}
