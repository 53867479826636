.FleetsManager {
  position: absolute;
  top: 5px;
  right: 0;

  .ant-btn-link {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }
}
