@import 'src/styles/variables';
@import 'src/styles/mixins';

.ProviderEditor {
  padding-bottom: 50px;

  &__loader {
    .LoaderOverlay__spinner {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  .ProviderEditor__eventButton.ant-btn {
    font-size: 16px;
    top: -7px !important;

    @include breakpoint(sm) {
      top: 3px !important;
    }
  }

  &__main {
    padding: 1rem 0;

    &--info {
      display: flex;
      flex-direction: row;
      position: relative;
      flex-wrap: wrap;
      padding: 1rem 0 0;
    }
  }

  &__map {
    width: 35%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: grey;
    margin-bottom: 1rem;

    @media (max-width: 576px) {
      width: 100%;
      height: 300px;
    }

    h2,
    div {
      font-family: $font-ttl;
      font-weight: 700;
      text-align: center;
    }

    div {
      font-weight: 400;
    }
  }

  .ExpanderHeader__header .ant-btn span {
    display: inline-block;
  }
}
