.app-d-none {
  display: none !important;
}

.app-d-block {
  display: block !important;
}

.app-d-i-block {
  display: inline-block !important;
}

.app-d-xs-none {
  @include breakpoint(sm) {
    display: none !important;
  }
}

.app-d-sm-none {
  @include breakpoint(md) {
    display: none !important;
  }
}

.app-d-md-none {
  @include breakpoint(lg) {
    display: none !important;
  }
}

.app-d-lg-none {
  @include breakpoint(xlg) {
    display: none !important;
  }
}

.app-d-xs-block {
  @include breakpoint(sm) {
    display: block !important;
  }
}

.app-d-sm-block {
  @include breakpoint(md) {
    display: block !important;
  }
}

.app-d-md-block {
  @include breakpoint(lg) {
    display: block !important;
  }
}

.app-d-lg-block {
  @include breakpoint(xl) {
    display: block !important;
  }
}

.app-d-xlg-block {
  @include breakpoint(xlg) {
    display: block !important;
  }
}
