.CreateFleet {
  overflow: hidden;
  height: 40px;
  transition: height 0.25s;

  &--active {
    height: 152px;
  }

  &--error {
    height: 180px;
  }

  &__btns {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.3;
    margin: 0 0 26px;
    text-align: center;
  }
}
