@import "src/styles/variables";
@import "src/styles/mixins";

.ProductForm {

  &__title,
  &__short {
    position: relative;
  }

  &__counter {
    position: absolute;
    font-size: 10px;
    line-height: 18px;
    right: 5px;
    bottom: 0;
    color: $grey-medium;

    &--warning {
      color: $red;
    }
  }

  .ant-form-explain {
    position: absolute;
    padding-top: 4px;
    line-height: 16px;

    @include breakpoint(md) {
      font-size: 10px;
      line-height: 14px;
      padding-right: 30px;
    }
  }
}
