@import "src/styles/variables";
@import "src/styles/mixins";

.SaveCancelGroup {
  margin-right: -10px;
  display: flex;
  justify-content: flex-end;

  @include breakpoint(sm) {
    flex-direction: column;
    margin-right: 0;
  }

  .ant-btn {
    margin: 0 10px;

    &:first-child {
      @include breakpoint(sm) {
        margin-bottom: 24px;
      }
    }

    @include breakpoint(sm) {
      display: block;
      width: 100%;
      margin: 0;
    }
  }
}
