@import "src/styles/variables";

.PlanCard {
  position: relative;
  height: 140px;
  background-color: whitesmoke;
  border: solid 1px lightgrey;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken(whitesmoke, 10);
  }

  &--holder {
    padding: 1rem;
    width: 43%;
    max-width: 450px;

    @media screen and (max-width: 768px) {
      width: 40%;
    }

    @media screen and (max-width: 576px) {
      width: 250px;
    }

    @media screen and (max-width: 414px) {
      width: 210px;
    }
  }

  &__price {
    font-family: $font-ttl;
    font-size: 3rem;
    font-weight: 700;
    color: $color-green;
    line-height: 1;

    small {
      font-family: $font-ttl;
      font-weight: 700;
    }
  }

  &__description {
    font-family: $font-ttl;
    font-weight: 400;
    font-size: 0.75rem;
    margin-top: 0.75rem;
    padding: 0 2rem;
    text-align: justify;
  }

  &__chosen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(darken(whitesmoke, 3), 0.75);

    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }

  &__icon {
    color: mediumseagreen;

    &--holder {
      width: 3rem;
      height: 3rem;
      background-color: white;
      border-radius: 50%;
    }
  }

}
