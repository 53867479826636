@import 'src/styles/variables';
@import 'src/styles/mixins';

.VesselPage {
  padding: 16px 0;

  &__top {
    display: flex;

    @include breakpoint(md) {
      display: block;
    }
  }

  &__info {
    width: 41%;
    margin-bottom: 45px;

    @include breakpoint(md) {
      width: 100%;
    }
  }

  &__gallery {
    width: 59%;
    padding: 0 25px;
    margin-bottom: 45px;

    @include breakpoint(md) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    font-size: 23px;
    line-height: 1;
    color: #dadada;
    margin-bottom: 18px;

    > div {
      margin-right: 16px;
    }
  }

  &__collapse.ant-collapse.ant-collapse-borderless {
    background: inherit;
    padding-bottom: 60px;
  }

  &__collapsePanel {
    .ant-collapse-content {
      .ant-collapse-content-box {
        background: var(--component-background);
        padding-top: 0 !important;
        padding-right: 0;
        padding-left: 0;

        .ant-tabs-nav-wrap {
          background: var(--body-background);

          > .ant-tabs-nav-list > .ant-tabs-tab {
            background: inherit;

            &.ant-tabs-tab-active {
              background: var(--component-background);
              border-bottom: 1px solid var(--component-background);
            }
          }
        }
      }
    }
  }
}
