@import '../../styles/variables';
@import '../../styles/mixins';

.Aside {
  top: 66px;
  height: calc(100% - 66px);

  .ant-drawer-content-wrapper {
    width: 227px !important;

    @include breakpoint(xlg-up) {
      width: 207px !important;
    }

    @include breakpoint(xl-up) {
      width: 227px !important;
    }
  }

  &.ant-drawer-left {
    @include breakpoint(xlg-up) {
      width: 207px;
    }

    @include breakpoint(xl-up) {
      width: 227px;
    }
  }

  @include breakpoint(xlg-up) {
    .ant-drawer-wrapper-body,
    .ant-drawer-content-wrapper {
      box-shadow: none !important;
      transform: translateX(0) !important;
    }

    .ant-drawer-mask {
      display: none;
    }
  }

  @media screen and (min-width: 1490px) {
    left: calc(50% - 745px);
  }
}
