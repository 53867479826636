.ant-slider {
  margin-top: 16px;

  .ant-slider-with-marks {
    margin-bottom: 24px;
  }

  .ant-slider-rail {
    background: $grey-light;
    height: 2px;
  }

  .ant-slider-track {
    background: $blue-sea;
    height: 2px;
  }

  .ant-slider-handle {
    width: 12px;
    height: 12px;
    border: 0;
    background: $blue-sea;
  }

  .ant-slider-mark {
    font-size: 12px;
  }

  .ant-slider-mark-text {
    color: var(--text);
    opacity: 0;

    &.ant-slider-mark-text-active {
      opacity: 1;
    }
  }
}
