@import 'src/styles/variables';

.PersonPositions {
  width: 100%;

  th {
    font-weight: 200;
    color: $grey-medium;
    padding: 3px 10px 3px 0;
    width: 50%;
  }

  td {
    padding: 3px 10px 3px 0;
    color: var(--table-text);
  }
}
