@import 'src/styles/variables';

.ArticleTitle {
  &__title {
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  &__created {
    font-style: normal;
    display: block;
    margin: 0 0 1rem;
    font-size: 13px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $grey-medium;
  }

  &__link {
    color: $grey-medium;
    text-decoration: underline;
  }
}
