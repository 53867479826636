@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.VesselsMap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 100;

  #eezMap,
  #seabedMap,
  #leafletMap {
    width: 100%;
    height: 100%;
  }

  &__map {
    height: 100%;
    position: relative;
  }

  &__logo {
    position: absolute;
    bottom: 15px;
    right: 10px;
    max-width: 325px;
    z-index: 999;
    filter: drop-shadow(0px 0px 2px rgba(2, 8, 20, 0.3));

    @media screen and (max-width: 767px) {
      display: none;
      z-index: -1;
    }
  }

  &__layout {
    position: relative;
    height: 100%;
  }

  &__search {
    color: $black;
    display: none;
    width: 350px;
    top: 30px;
    left: 15px;

    @include breakpoint(md-down) {
      width: 100%;
      height: 70px;
      left: 0;
      top: 0;
    }

    .HeaderSearch__input {
      margin: 0;

      .Search__icon {
        color: var(--text) !important;
      }

      .ant-input {
        color: var(--text) !important;
      }
    }

    .HeaderSearch__icon {
      background: var(--body-background);
      color: var(--text) !important;
      padding: 0 5px;
      width: 38px;
      height: 38px;
      top: 31px;
      left: 15px;
      border-radius: 50%;

      &:hover {
        color: $blue-sea !important;
        background: var(--component-background);
      }
    }

    .ant-input {
      background-color: var(--body-background) !important;
    }
  }

  &.map-fullscreen-active {
    .VesselsMap__search {
      display: block;
      position: absolute;
      z-index: 1112;
    }

    #embed-zoom {
      top: 80px !important;
    }

    .MapModeSwitch,
    .slideout.slideout-bottom,
    .VesselsMapControl__top-right {
      visibility: hidden;
    }

    .leaflet-top .leaflet-control {
      margin-top: 0 !important;
      top: 80px !important;

      &.leaflet-control-coordinates {
        top: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
