@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.EventSchedule {
  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    margin: 0 0 1rem;
  }

  &__group {
    display: flex;
  }

  &__date {
    min-width: 76px;
    padding-top: 3px;
    color: $grey-medium;

    strong {
      color: var(--title);
    }
  }

  &__message {
    margin-top: -1rem;
  }

  .ScheduleItems {
    flex: auto;
  }
}
