.FleetsList {
  margin: 0;
  padding: 0 20px 10px;
  list-style: none;

  &__item {
    padding: 10px 0;
  }

  &__btn {
    padding-top: 30px;
  }

  .ant-checkbox-wrapper {
    font-size: 18px;
  }
}