@import 'src/styles/variables';
@import 'src/styles/mixins';

.VesselsMapControl {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  width: 100%;
  background-color: var(--body-background);
  padding: 1rem 1rem 0.75rem;

  &__selectors {
    display: flex;
    flex-direction: row;
    min-width: 100px;
    padding-top: 0.25rem;
    margin-bottom: 0.5rem;

    label {
      padding-top: 0;
      padding-bottom: 0;
      font-family: Arial, sans-serif;

      @media only screen and (max-width: 414px) {
        line-height: 1;
      }
    }
  }

  &__select {
    width: 6rem;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: var(--component-background);
  }

  &__icons {
    font-size: 20px;
    color: SteelBlue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    &--ship {
      transform: rotate(90deg);
      margin-left: 10px;
    }
  }

  &__top-right {
    position: absolute;
    top: 35px;
    right: 16px;
    z-index: 1000;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
    background-color: var(--body-background);
    border: 1px solid var(--button-border-color);

    @include breakpoint(md) {
      top: 132px;
    }

    .ant-btn {
      color: var(--title);

      &:hover {
        color: $blue-sea;
      }

      &[disabled] {
        color: var(--button-map-disabled);

        &:hover {
          color: var(--button-map-disabled);
        }
      }
    }
  }

  &__btnsHolder {
    position: absolute;
    bottom: 20px;
    left: 70px;
    z-index: 1000;

    .ant-btn {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;
      border: 0;
      margin-right: 20px;
    }
  }
}
