@import 'src/styles/variables';
@import 'src/styles/mixins';

.VideosEditor {
  &__main {
    position: relative;

    .ImageInput2 .ant-upload-text {
      @include breakpoint(md) {
        font-size: 11px;
        padding-top: 5px;
      }
    }

    .ant-upload.ant-upload-drag .ant-upload {
      @include breakpoint(md) {
        padding: 0;
      }
    }
  }

  &__poster {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 250px;
    border: solid 2px whitesmoke;
    margin-bottom: 0;
    background: #c4c4c4;
    z-index: 2;

    @include breakpoint(md) {
      width: 200px;
    }

    .ant-upload.ant-upload-drag {
      min-height: 140px;
      padding: 5px 20px;

      @include breakpoint(md) {
        min-height: 84px;
        padding: 10px;
      }
    }
  }

  .CardWrapper {
    min-width: 460px;
    max-width: 460px;

    @include breakpoint(md) {
      min-width: 304px;
      max-width: 304px;
    }  
  }

  .ant-collapse-content-active {
    .CardWrapper {
      max-width: 50%;
      min-width: 50%;
      padding-right: 18px;

      @include breakpoint(sm) {
        max-width: 100%;
        min-width: 100%;
      }

      .VideoCard {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
