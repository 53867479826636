@import 'src/styles/variables';
@import 'src/styles/mixins';

.Expander2 {
  &__slider {
    height: 100%;
    width: 100%;
  }

  &__content {
    padding-top: 20px;
    padding-left: 2px;
  }

  .ant-collapse {
    background: none;

    > .ant-collapse-item {
      border: 0;

      &.ant-collapse-item-active {
        .CardWrapper {
          .EventCardHorizontal {
            min-width: 100%;
            max-width: 100%;
          }
        }

        .EventCardHorizontal {
          min-width: calc(100% - 15px);
          max-width: calc(100% - 15px);

          @include breakpoint(md) {
            display: block;
          }

          &__photo {
            @include breakpoint(md) {
              min-width: calc(100% - 4px);
              max-width: calc(100% - 4px);
              margin-right: 2px !important;
              margin-left: 2px !important;
              height: 160px;
            }
          }

          &__description {
            max-height: inherit;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .ant-collapse-content {
    border: 0;
    padding-top: 20px;

    > .ant-collapse-content-box {
      padding: 20px 0 25px;
    }
  }

  .ant-collapse-content-active {
    .Expander2__list {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
    }
  }
}
