@import 'src/styles/variables';

.ServiceLogo {
  top: auto !important;
  bottom: -25px;
  left: 16px !important;
  max-height: 78px !important;
  max-width: 166px !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  img {
    max-height: 76px !important;
  }

  &:hover {
    opacity: 1 !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 2px 2px 1px #d7d7d7;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__fallback {
    color: #2f76c3;
    font: italic 600 1.25rem/1 $font-ttl;
    text-transform: uppercase;
    position: absolute;
    left: 9px;
    bottom: -39px;
    border: 1px solid #e7e7e6;
    border-radius: 2px;
    background: $white;
    z-index: 5;
    min-width: 166px;
    max-width: 186px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
}
