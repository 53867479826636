@import 'src/styles/variables.scss';

.VesselPhotos {
  &__fullscreenIcon {
    font-size: 36px;
    color: $white;
  }

  & .image-gallery-icon.VesselPhotos__fullscreenButton {
    &:hover {
      .VesselPhotos__fullscreenIcon {
        position: relative;
        font-size: 40px;
        right: -2px;
        bottom: -2px;
      }
    }

    &--active.image-gallery-fullscreen-button {
      top: 0;
      bottom: auto !important;

      .VesselPhotos__fullscreenIcon {
        color: $error;
      }

      &:hover {
        .VesselPhotos__fullscreenIcon {
          top: -2px;
        }
      }
    }
  }

  &__placeholder {
    width: 100%;
  }
}
