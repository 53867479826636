.FishingRights {
  margin-bottom: 20px;

  .ExpanderHeader__title {
    font-size: 14px !important;
    font-weight: 200 !important;
    text-transform: unset;
  }

  &__title {
    text-transform: uppercase;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-left: 0;
  }

  .ant-collapse-content {
    overflow: visible;

    > .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__collapse.ant-collapse.ant-collapse-borderless {
    background: inherit;
  }

  &__collapse .ExpanderHeader__title {
    font-size: 18px;
    text-transform: uppercase;
    margin-left: 10px;
  }
}
