@import 'src/styles/variables';
@import 'src/styles/mixins';

.ServiceInfo {
  position: relative;
  padding: 10px 40px 10px 0;
  flex: auto;

  @include breakpoint(md) {
    padding-right: 20px;
  }

  &__site {
    margin: 60px 0 21px;

    a {
      color: var(--service-link);
      text-decoration: var(--link-underline);
      word-break: break-all;
    }
  }

  &__name {
    font: bold 32px/42px $font-ttl;
    margin: 0 0 10px;
  }

  p {
    font-size: 15px;

    &:first-of-type {
      color: var(--text);
    }
  }
}
