@import 'src/styles/variables';

.UserPage {
  display: flex;
  align-content: center;
  align-items: center;
  height: 400px;

  &__form {
    max-width: 350px;
    min-width: 310px;
    margin: 0 auto;
  }

  &__title {
    font: bold 28px/1.3 $font-ttl;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 36px;
  }
}
