.ForecastPlayer {
  &__button {
    height: 38px;
    width: 38px;
    padding: 0;
    margin-top: 38px;
    background-color: var(--body-background) !important;
    border: 1px solid var(--button-map-border) !important;

    &:after {
      display: none;
    }

    &--mobile {
      margin-top: 0;
    }

    &:hover {
      background-color: var(--component-background) !important;
    }
  }

  &__indicator {
    position: absolute;
    background-color: #4e74fc;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: 5px;
    right: 7px;
  }
}
