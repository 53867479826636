textarea.ant-input {
  resize: none;
}

.ant-input {
  font-size: 16px;

  &:focus,
  &:hover {
    box-shadow: none !important;
  }
}

.ant-select .ant-select-arrow {
  color: $grey-medium-2;
}
