@import './styles';

// Components
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.fullscreen/Control.FullScreen.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~react-phone-input-2/lib/style.css';
@import '~react-fishfacts/dist/index.css';
//@import '~react-image-gallery/styles/scss/image-gallery.scss';

#app {
  height: 100%;
  overflow: hidden;
}

.App {
  height: 100%;
}
