@import 'src/styles/variables';
@import 'src/styles/mixins';

.PromotedArticle {
  position: relative;

  &__contact {
    position: absolute;
    bottom: 53px;
    right: -3px;
  }

  &__label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: $color-green-light;
    color: $white;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 8px;
  }

  &__text {
    color: $black-dark;
    font-size: 10px;
    font-weight: normal;
    margin: -3px 0;
  }

  .LatestArticle__description:after {
    @include multiLineEllipsis(
      $lineHeight: 1.5rem,
      $lineCount: 4,
      $bgColor: var(--component-background)
    );
  }
}
