@import 'src/styles/variables';

.PromoButton {
  &__label {
    display: inline-block;
    background: $color-green-light;
    color: white;
    font-size: 14px;
    padding: 2px 12px;
    border-radius: 12px;
  }
}
