@import 'src/styles/variables';
@import 'src/styles/mixins';

.PromotionForm {
  &__row {
    @include breakpoint(sm-up) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    padding-bottom: 25px;

    @include breakpoint(md-up) {
      width: 50%;
    }

    @include breakpoint(xlg-up) {
      width: 59.7%;
    }

    &:first-child {
      @include breakpoint(sm-up) {
        max-width: 200px;
        width: 100%;
      }

      @include breakpoint(xl-up) {
        max-width: 222px;
      }
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .BasicFilter {
    &__row {
      padding: 0;

      .ant-form-item {
        margin-bottom: 14px;
      }
    }
  }

  .CountriesSelect {
    @include breakpoint(md-up) {
      max-width: 222px;
    }
  }
}
