@import 'src/styles/variables';
@import 'src/styles/mixins';

.EventCardHorizontal {
  display: flex;
  min-width: 780px;
  max-width: 780px;
  margin: 0 1rem 2rem 0;
  color: $text-color;

  &:hover {
    color: $text-color;
  }

  &__holder {
    display: flex;
  }

  &__photo {
    overflow: hidden;
    display: flex;
    height: 160px;
    align-items: center;
    justify-content: center;
    margin: 0 16px 18px 0 !important;
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    min-width: 286px;
    max-width: 286px;

    @include breakpoint(md) {
      min-width: 186px;
      max-width: 186px;
      height: 100px;
    }

    img {
      width: 100%;
    }
  }

  &__name {
    font: bold 22px/24px $font-ttl;
    margin: 0 0 20px;
    color: var(--title) !important;
  }

  &__description {
    @include multiLineEllipsis(
      $lineHeight: 1.5rem,
      $lineCount: 5,
      $bgColor: var(--body-background)
    );
    font-size: 16px;
    line-height: 1.5;
    color: var(--text);

    @include breakpoint(md) {
      font-size: 15px;
    }
  }

  .EventDates {
    min-width: 60px;
    max-width: 60px;
  }
}
