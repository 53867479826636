@import 'src/styles/variables';
@import 'src/styles/mixins';

.Page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 40px;
  color: $black;

  @include breakpoint(md-up) {
    padding-top: 110px;
  }

  &__title {
    display: block;
    font-size: 62px;
    line-height: 75px;
    margin-bottom: 10px;
  }

  .ant-btn {
    font-size: 18px;
  }

  p {
    font-size: 24px;
    line-height: 29px;
    margin: 0 auto 30px;
    max-width: 80%;
  }

  img {
    margin-bottom: 15px;

    @include breakpoint(md-up) {
      padding-top: 45px;
    }
  }
}
