@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.FishingQuotes {
  &__title {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    color: var(--title);

    th,
    td {
      text-align: left;
      overflow: hidden;
      padding: 13px 10px;
      vertical-align: top;
      font-size: 16px;
      width: 50%;

      @include breakpoint(md) {
        padding-left: 0;
      }

      &:nth-child(1) {
        width: 52%;
      }
      &:nth-child(2) {
        width: 48%;
      }
    }

    th {
      border-bottom: 1px solid #c4c4c4;
      font-weight: 600;
      text-transform: uppercase;
    }

    > tbody {
      > tr {
        &:hover {
          > td {
            background: rgba(196, 196, 196, 0.2);
          }
        }
      }
    }
  }

  &__scrollVertical {
    height: 400px;
    overflow-y: auto;
    width: calc(100% + 1rem);
  }

  &__scrollHorizontal {
    overflow-x: auto;
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &__touchDevice {
    padding-right: 0;
    margin-right: 0;

    .FishingQuotes__scrollVertical {
      height: 300px;
      width: 100%;
    }
  }
}
