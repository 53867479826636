@import 'src/styles/variables';

.checkbox {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2);
  /* IE */
  -moz-transform: scale(2);
  /* FF */
  -webkit-transform: scale(2);
  /* Safari and Chrome */
  -o-transform: scale(2);
  /* Opera */
  padding: 10px;
  z-index: 10;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
}

.vessel-top {
  display: flex;
  justify-content: flex-start;

  .link {
    margin-left: 10px;
  }
}

.VesselListPage {
  flex: 1;

  &__list,
  &__list-container {
    flex: 1;
    outline: none;
  }

  &__list-container {
    height: 100%;
    margin: 0 -10px;
  }

  &__list-wrapper {
    height: 100%;
    padding-top: 1rem;
    overflow-x: hidden;
  }

  &__list {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__favBtn {
    position: absolute;
    top: 8px;
    right: 20px;
  }
}

.Vessel__mainWrapper {
  position: relative;
  height: calc(100vh - 70px);

  &--panel {
    height: calc(100vh - 111px);
  }

  &--panel-active {
    padding-top: 44px;
  }
}

@media (max-width: 480px) {
  .posts {
    .post {
      width: 100%;
    }
  }
}
