@import 'src/styles/variables.scss';

.VesselOwner {
  display: flex;

  &__pane {
    min-width: 280px;
    max-width: 280px;
    padding: 0 60px 70px 0;
    line-height: 1.5;
  }

  &__title {
    font: bold 18px/1.3 $font-inter;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__subTitle {
    display: block;
    font-size: 18px;

    .link-underlined {
      color: var(--link);
      text-decoration: underline;
    }
  }

  &__data {
    font-size: 14px;
  }
}
