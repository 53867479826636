.ant-drawer {
  &.RightSideDrawer {
    &__main {
      top: 66px;
      height: calc(100% - 66px);

      .ant-drawer-body {
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .ant-switch {
        background-color: $black;
      }

      .ant-switch-checked {
        background-color: $blue-sea;
      }

      .ant-drawer-content {
        overflow: visible;
      }
    }
  }
}

.RightSideDrawer {
  &__burger {
    margin: 3px 10px 0 0;
  }

  &__header {
    background: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 10px 11px;

    h3 {
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }

    .ant-btn-link {
      padding-left: 10px;
      padding-right: 10px;
      color: $grey-medium;
      border: 0;

      &:focus,
      &:active,
      &:hover {
        background: rgba(242, 242, 242, 0.2);
        color: $blue-sea;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  &__content {
    padding: 0 10px;
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .ant-tabs-content-holder {
      padding-top: 20px;
      background-color: var(--component-background);
    }

    div.ant-tabs-nav {
      width: 100%;
      margin-bottom: 0;

      &-wrap {
        padding: 10px 10px 0;
      }

      > div {
        display: flex;

        .ant-tabs-tab {
          flex-grow: 1;
          text-align: center;
          background-color: var(--body-background);

          &-active {
            background-color: var(--component-background);
            border-bottom-color: var(--component-background) !important;
          }
        }
      }
    }
  }
}
