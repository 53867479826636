@import 'src/styles/variables';
@import 'src/styles/mixins';

.ProviderEditorBanner {
  position: relative;

  @include breakpoint(xlg) {
    margin: 0 -20px;
  }

  &__menu {
    bottom: unset;
  }

  &__buttons {
    position: absolute;
    bottom: 12px;
    right: 12px;

    .ant-btn-group {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      border-radius: 36px;

      > .ant-btn {
        &:first-child:not(:last-child),
        .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
          border-top-left-radius: 36px;
          border-bottom-left-radius: 36px;
        }

        &:last-child:not(:first-child),
        .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
          border-top-right-radius: 36px;
          border-bottom-right-radius: 36px;
        }
      }
    }

    .ant-btn {
      font-size: 16px;
      min-width: 150px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .ImageInput2__info {
    opacity: 0.8;
  }

  &__logoHolder {
    position: absolute;
    bottom: -25px;
    left: 16px;
    width: 188px;
    height: 90px;
    overflow: hidden;
    border: 1px solid $grey-light;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

    .ImageInput2 {
      opacity: 0.8;
      margin-bottom: 0;

      &__info {
        padding: 6px;
      }

      .ant-upload {
        &.ant-upload-drag {
          min-height: 88px;
          padding: 0;

          .ant-upload {
            padding: 0;
          }
        }
      }

      .ant-progress {
        transform: scale(0.5);
      }
    }
  }
}
