@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.ScheduleItem {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__range {
    color: $grey-medium;
  }

  &__title {
    font-weight: bold;
    color: var(--title);
    margin-bottom: 11px;
  }

  .ant-btn {
    height: 32px;
  }

  &__person {
    background: #f4f4f4;
    margin: 0 0 44px 70px;
    padding: 7px 0;
    border-radius: 2px;

    @include breakpoint(md) {
      margin-left: 0;
    }

    table {
      width: 100%;
      table-layout: fixed;

      th,
      td {
        padding: 3px 10px;
        font-weight: normal;
      }

      th {
        color: $grey-medium-2;
        width: 100px;
      }

      td {
        color: $black;
        word-break: break-all;
      }
    }
  }

  &__footer {
    margin: 0 0 24px 70px;

    @include breakpoint(md) {
      margin-left: 0;
    }
  }
}
