@import './fonts.scss';
@import './variables.scss';

html,
body {
  margin: 0;
  font-size: 16px;
  height: 100%;
}

body {
  font-family: $font-inter;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-ttl;
  word-break: break-word;
}

aside,
main,
header,
footer {
  display: block;
}

header,
footer {
  overflow: unset;
  padding: 0;
}

//reset margins
figure {
  margin: 0 !important;
}

//reset margins
input {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

// Overrides
a {
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
}