@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.HeaderSearch {
  &__closeButton,
  &__icon {
    color: $white !important;

    @include breakpoint(md-up) {
      display: none;
    }
  }

  &__input {
    margin: 0 40px;
    max-width: 460px;

    @include breakpoint(xlg-down) {
      margin: 0;
    }

    @include breakpoint(md-down) {
      max-width: 100%;
    }
  }

  &__inputWrapper {
    .HeaderSearch__closeButton {
      &:focus,
      &:active,
      &:hover {
        color: $grey-medium;
      }
    }

    @include breakpoint(md-down) {
      @include transition;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background: $header-black;
      padding: 14px 0 14px 20px;
      transform: translateY(-100%);

      &--active {
        transform: translateY(0);
      }
    }
  }

  //antd styles override
  .ant-input-affix-wrapper {
    display: flex;
    background: transparent;
    box-shadow: none !important;
    border: none;
    padding: 0;
  }

  .ant-input-suffix {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}

.HeaderSearch,
.HeaderSearch__inputWrapper {
  display: flex;
  justify-content: center;
  flex: auto;

  @include breakpoint(xlg-down) {
    justify-content: flex-end;
  }

  @include breakpoint(md-down) {
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }
}
