@import 'src/styles/variables.scss';
@import 'src/styles/mixins';

.Article {
  @include breakpoint(xlg) {
    padding-top: 0.75rem;
  }

  &__summary {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.4;
    color: var(--title);
  }

  &__description {
    margin-left: auto;
    max-width: 640px;

    @include breakpoint(sm) {
      margin-right: 0;
    }
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  &__body {
    max-width: 540px;

    h2,
    h3,
    h4 {
      margin: 1.5rem 0;
      font-weight: bold;
    }

    img {
      width: 100%;
      cursor: pointer;
    }

    .ql-video {
      width: 540px;
      height: 310px;
    }
  }
}
