@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.RejectApprove {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: -30px;

  @include breakpoint(md) {
    display: block;
  }

  .ant-btn {
    margin-left: 5px;
    min-width: 140px;

    @include breakpoint(md) {
      margin-bottom: 10px;
    }
  }

  .ant-btn-link {
    color: $grey-medium;
  }
}
